import styled from 'styled-components/macro'
import { margin, maxWidth } from 'styled-system'

export const Text = styled.div`
  ${margin};
  ${maxWidth};

  p {
    margin-bottom: 8px;

    :last-child {
      margin-bottom: 0;
    }
  }

  a {
    transition: all 0.3s ease;
    text-decoration: underline;

    :hover {
      text-decoration: none;
    }
  }
`

export default Text
