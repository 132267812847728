export const FETCH_PAGE_REQUEST = 'FETCH_PAGE_REQUEST'
export const FETCH_PAGE_SUCCESS = 'FETCH_PAGE_SUCCESS'
export const FETCH_PAGE_FAILURE = 'FETCH_PAGE_FAILURE'

export const fetchPageRequest = slug => ({
  type: FETCH_PAGE_REQUEST,
  payload: { slug },
})

export const fetchPageSuccess = page => ({
  type: FETCH_PAGE_SUCCESS,
  payload: { page },
})

export const fetchPageFailure = error => ({
  type: FETCH_PAGE_FAILURE,
  error,
})
