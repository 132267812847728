import React from 'react'
import { ReactComponent as SpaceBlue } from 'assets/img/icons/space-blue.svg'
import { ReactComponent as SpacePink } from 'assets/img/icons/space-pink.svg'
import { ReactComponent as SpaceRed } from 'assets/img/icons/space-red.svg'
import { ReactComponent as SpaceBlack } from 'assets/img/icons/space-black.svg'
import { ReactComponent as SpaceSilver } from 'assets/img/icons/space-silver.svg'
import { ReactComponent as SpaceWhite } from 'assets/img/icons/space-white.svg'
import { ReactComponent as SpaceGreen } from 'assets/img/icons/space-green.svg'
import { ReactComponent as SpaceOrange } from 'assets/img/icons/space-orange.svg'
import { ReactComponent as SpaceYellow } from 'assets/img/icons/space-yellow.svg'
import { ReactComponent as SpaceSocial } from 'assets/img/icons/space-social.svg'
import { ReactComponent as SpacePurple } from 'assets/img/icons/space-purple.svg'
import { ReactComponent as SpaceBeauty } from 'assets/img/icons/space-beauty.svg'
import { ReactComponent as SpacePrivate } from 'assets/img/icons/space-private.svg'
import { ReactComponent as IconSpace } from 'assets/img/icons/space.svg'

const svgs = {
  Blue: {
    cmp: <SpaceBlue />,
    paths: [23, 78],
    transform: '-31%',
    transforms: ['0%', '-1%', '28%', '28%', '28%', '28%'],
    preview: '28px',
    color: '#73FCEA',
  },
  Pink: {
    cmp: <SpacePink />,
    paths: [20, 80],
    transform: '-38%',
    transforms: ['0%', '-1%', '36%', '36%', '36%', '36%', '30%'],
    preview: '38px',
    color: '#FF0080',
  },
  Red: {
    cmp: <SpaceRed />,
    paths: [29, 80],
    transform: '-40%',
    transforms: ['0%', '0%', '-1%', '40%', '40%', '28%'],
    preview: '35px',
    color: '#FA2A47',
  },
  White: {
    cmp: <SpaceWhite />,
    paths: [20, 80],
    transform: '-29%',
    transforms: ['0%', '0%', '-1%', '29%', '29%', '29%', '29%', '34%'],
    preview: '30px',
    color: '#FFFFFF',
  },
  Purple: {
    cmp: <SpacePurple />,
    paths: [18, 83],
    transform: '-26%',
    transforms: ['0%', '0%', '0%', '0%', '0%', '-1%', '25%', '25%', '34%'],
    preview: '42px',
    color: '#BD1DFF',
  },
  Green: {
    cmp: <SpaceGreen />,
    paths: [20, 80],
    transform: '-29%',
    transforms: ['0%', '0%', '0%', '-1%', '28%', '28%', '28%', '34%'],
    preview: '40px',
    color: '#45FA67',
  },
  Orange: {
    cmp: <SpaceOrange />,
    paths: [20, 75],
    transform: '-17%',
    transforms: ['0%', '0%', '-1%', '17%', '17%', '17%', '17%', '17%', '30%'],
    preview: '30px',
    color: '#FF7701',
  },
  Black: {
    cmp: <SpaceBlack />,
    paths: [20, 80],
    transform: '-26%',
    transforms: ['0%', '0%', '-1%', '26%', '26%', '26%', '26%', '30%'],
    preview: '36px',
    color: '#FFFFFF',
  },
  Yellow: {
    cmp: <SpaceYellow />,
    paths: [20, 80],
    transform: '-18%',
    transforms: ['0%', '0%', '0%', '-1%', '15%', '15%', '15%', '15%', '24%'],
    preview: '26px',
    color: '#FFF200',
  },
  Silver: {
    cmp: <SpaceSilver />,
    paths: [20, 80],
    transform: '-21%',
    transforms: ['-20%', '-20%', '-20%', '-20%', '-20%'],
    preview: '30px',
  },
  Social: {
    cmp: <SpaceSocial />,
    paths: [18, 83],
    transform: '-38%',
    transforms: ['-38%', '-38%'],
    preview: '60px',
  },
  Private: {
    cmp: <SpacePrivate />,
    paths: [24, 86],
    transform: '-24%',
    transforms: ['-24%', '-24%', '-24%', '-24%', '-24%', '-24%', '-24%'],
    preview: '44px',
  },
  Beauty: {
    cmp: <SpaceBeauty />,
    paths: [24, 86],
    transform: '-32%',
    transforms: ['-32%', '-32%'],
    preview: '53px',
  },
}

const SvgComponent = ({ name }) => {
  const { transform, paths, transforms, preview, color } = svgs[name]

  return (
    <React.Fragment>
      <span id={`space-logo-span-${name}`}>
        <style
          dangerouslySetInnerHTML={{
            __html: `
              #space-${name} {
                overflow: visible;
                display: inline-block;
                width: initial;
              }
              
              .cmp_black__spaces-item_label {
                display: inline-block;
                transition: 0.2s ease-out;
                opacity: 0;
              }

              #space-${name} path {
                will-change: transform, clip-path, fill;
                transform-style: preserve-3d;
                transition: all 0.15s ease-out;
              }

              #space-${name}-Stretch {
                opacity: 0;
              }

              #space-${name}-Stretch-after {
                opacity: 0;
              }

              #space-${name}-Standart {
                opacity: 1;
              }
              
              .-is-active--true #space-logo-span-${name},
              .-is-active--true #space-logo-span-${name} + .cmp_black__spaces-item_label,
              #space-logo-span-${name}:hover,
              #space-logo-span-${name}:hover + .cmp_black__spaces-item_label {
                color: ${color};
              }
          
              @supports(-webkit-clip-path: polygon(0 0, 100% 0, 100% 75%, 0 100%)) or (clip-path: polygon(0 0, 100% 0, 100% 75%, 0 100%)) {
                ${transforms
                  .map(
                    (opt, i) =>
                      `#space-${name}:hover path:nth-child(${i + 1}) {
                    transform: translateX(${opt});
                    -webkit-transform: translateX(${opt});
                  }
                  .-is-active--true #space-${name} path:nth-child(${i + 1}) {
                    transform: translateX(${opt});
                    -webkit-transform: translateX(${opt});
                  }`,
                  )
                  .join('')}
                
                .-is-active--true #space-logo-span-${name} + .cmp_black__spaces-item_label,
                #space-logo-span-${name}:hover + .cmp_black__spaces-item_label {
                  transform: translateX(${transforms[transforms.length - 1]});
                  -webkit-transform: translateX(${transforms[transforms.length - 1]});
                  opacity: 1;
                }

                .-is-active--true #space-logo-span-${name},
                .-is-active--true #space-logo-span-${name} + span {
                  transform: translateX(${preview});
                  -webkit-transform: translateX(${preview});
                }

                #space-${name}:hover #space-${name}-Stretch,
                .-is-active--true #space-${name} #space-${name}-Stretch {
                  clip-path: polygon(0% 0%, ${paths[1]}% 0%, ${paths[1]}% 100%, 0% 100%);
                  -webkit-clip-path: polygon(0% 0%, ${paths[1]}% 0%, ${paths[1]}% 100%, 0% 100%);
                }

                #space-${name}-Standart {
                  display: none;
                  opacity: 0;
                }

                #space-${name}-Stretch {
                  opacity: 1;
                  clip-path: polygon(0% 0%, ${paths[0]}% 0%, ${paths[0]}% 100%, 0% 100%);
                  -webkit-clip-path: polygon(0% 0%, ${paths[0]}% 0%, ${paths[0]}% 100%, 0% 100%);
                }
                
                #space-${name}-Stretch-after {
                  opacity: 1;
                  clip-path: polygon(${paths[1]}% 0%, 100% 0%, 100% 100%, ${paths[1]}% 100%);
                  -webkit-clip-path: polygon(${paths[1]}% 0%, 100% 0%, 100% 100%, ${
              paths[1]
            }% 100%);
                  transform: translateX(${transform});
                  -webkit-transform: translateX(${transform});

                }
              }
            `,
          }}
        />

        {svgs[name].cmp}
      </span>
      <span className="cmp_black__spaces-item_label">
        <IconSpace />
      </span>
    </React.Fragment>
  )
}

export default SvgComponent
