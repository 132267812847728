import React, { useState, useEffect, useMemo } from 'react'
import * as S from './styles'
import { ReactComponent as Arrow } from 'assets/svg/arrow.svg'
import { Animated } from 'react-animated-css'
import 'animate.css'
import SubscriptionPopup from '../SubscriptionPopup'

const TabsContent = ({
  template,
  subHeading,
  content,
  setBackgroundTransition,
  getActiveClub,
  initialClub,
}) => {
  const [contentVisibility, setContentVisibility] = useState(true)
  const [activeTab, setActiveTab] = useState(initialClub)
  const activeClub = useMemo(() => content.filter(item => item.id === activeTab)[0], [activeTab])

  const [renderedContent, setRenderedContent] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [mobileFocused, setMobileFocused] = useState(false)

  useEffect(() => {
    if (
      window.location.hash &&
      (window.location.hash.substring(1) === 'Mari' ||
        window.location.hash.substring(1) === 'Black' ||
        window.location.hash.substring(1) === 'Columbus')
    ) {
      setActiveTab(window.location.hash.substring(1))
    } else if (window.location.pathname !== '/subscription') {
      // console.log('1')
    } else {
      setModalIsOpen(true)
    }
  }, [])

  useEffect(() => {
    setContentVisibility(false)
    setBackgroundTransition(false)

    if (activeClub && window.location.pathname === '/subscription') {
      window.location.hash = activeClub.id
    }
    if (activeClub) {
      getActiveClub(activeClub.id)
    }

    setTimeout(() => {
      setBackgroundTransition(true)
      setContentVisibility(true)
      activeClub && setRenderedContent(activeClub.content)
    }, 300)
  }, [activeTab])

  const closeModalInitial = () => {
    setModalIsOpen(false)
    setActiveTab('Black')
  }

  return (
    <React.Fragment>
      <S.Wrapper template={template}>
        {subHeading && <S.SubHeading>{subHeading}</S.SubHeading>}
        <S.Header
          role="tablist"
          onClick={() => setMobileFocused(!mobileFocused)}
          isFocused={mobileFocused}
          aria-label={`Фильтр для ${subHeading}`}
        >
          <S.Arrow>
            <Arrow />
          </S.Arrow>
          {content.map((item, index) => (
            <S.HeaderItem
              template={template}
              isActive={activeTab === item.id}
              onClick={() => {
                setActiveTab(item.id)
              }}
              key={`tabTitle-${index}`}
            >
              {item.title.indexOf('.svg') > 0 && <img src={item.title} alt={item.alt} />}
              {item.title.indexOf('.svg') <= 0 && item.title}
            </S.HeaderItem>
          ))}
        </S.Header>
        <S.Container template={template}>
          {content.length && (
            <S.Content>
              <Animated isVisible={contentVisibility}>{renderedContent}</Animated>
            </S.Content>
          )}
        </S.Container>
      </S.Wrapper>
      <SubscriptionPopup
        modalIsOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        setActiveTab={setActiveTab}
        closeModalInitial={closeModalInitial}
      />
    </React.Fragment>
  )
}

export default TabsContent
