import React from 'react'
import DefaultTemplate from 'components/templates/Default'
import ScheduleList from 'components/molecules/ScheduleList'

const Schedule = ({ page }) => (
  <DefaultTemplate slug="schedule">
    <ScheduleList />
  </DefaultTemplate>
)

export default Schedule
