import styled, { css } from 'styled-components/macro'
import { screen } from 'config/theme'
import Button from 'components/atoms/Button'

export const Submit = styled(Button)``

export const Resend = styled(Button)``

export const Spinner = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  z-index: 5;

  svg {
    height: 58px;
    width: 58px;
    animation: rotate 1s linear infinite;
    align-self: center;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
`
export const FormWrapper = styled.div`
  position: relative;
  margin: 0 auto 80px;
  max-width: 580px;
`

export const Form = styled.form`
  position: relative;
  margin-bottom: 80px;

  ${({ isLoading }) => isLoading && css``}
`

export const Field = styled.div`
  position: relative;
  margin-bottom: 42px;

  ${screen.sm} {
  }
`

export const Label = styled.label`
  position: absolute;
  top: 21px;
  left: 25px;
  transition: all 0.2s ease-in;
  font-size: 20px;
  pointer-events: none;
  color: #888;
  z-index: 1;

  ${screen.sm} {
  }
`

export const Input = styled.input`
  display: block;
  position: relative;
  width: 100%;
  padding: 26px 25px 10px;
  transition: border-color 0.24s ease-in-out, box-shadow 0.24s ease-in-out;
  border: 1px solid #414141;
  border-bottom-color: #414141;
  background-clip: border-box;
  background-color: transparent;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  line-height: 1.6;

  &:focus {
    border-color: $color-secondary;
    outline: none;
    z-index: 1;
  }

  /* stylelint-disable */
  &:focus + ${Label}, &:not(:placeholder-shown) + ${Label} {
    top: 8px;
    font-size: 14px;
  }
  /* stylelint-enable */

  &::placeholder {
    color: transparent;
  }

  &:disabled {
    background-color: #ccc;
    box-shadow: none;
  }

  &::-webkit-inner-spin-button {
    display: none;
  }

  &::-webkit-calendar-picker-indicator {
    padding-right: 16px;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }
`

export const Textarea = styled.textarea`
  display: block;
  position: relative;
  width: 100%;
  padding: 26px 25px 10px;
  transition: border-color 0.24s ease-in-out, box-shadow 0.24s ease-in-out;
  border: 1px solid #414141;
  border-bottom-color: #414141;
  background-clip: border-box;
  background-color: transparent;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  line-height: 1.6;

  + ${Label} {
    top: 21px;
    left: 25px;
  }

  &:focus {
    border-color: $color-secondary;
    outline: none;
    z-index: 1;
  }

  /* stylelint-disable */
  &:focus + ${Label}, &:not(:placeholder-shown) + ${Label} {
    top: 8px;
    font-size: 14px;
  }

  /* stylelint-enable */

  &::placeholder {
    color: transparent;
  }

  &:disabled {
    background-color: #f9f9f9;
    box-shadow: none;
  }

  &::-webkit-inner-spin-button {
    display: none;
  }

  &::-webkit-calendar-picker-indicator {
    padding-right: 16px;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }
`

export const Response = styled.div`
  h3 {
    font-weight: 600;
    margin-bottom: 12px;

    ${screen.xs_min} {
      font-size: 28px;
    }

    ${screen.xs} {
      font-size: 21px;
    }
  }

  p {
    margin-bottom: 40px;
  }
`
