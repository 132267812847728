import React, { useState } from 'react'

import DefaultTemplate from 'components/templates/Default'
import Section from 'components/atoms/Section'
import Heading from 'components/atoms/Heading'
import Questions from 'components/molecules/Questions'
import SubscriptionList from 'components/molecules/SubscriptionList'
import TabsContent from 'components/molecules/TabsContent'
import TabsBackground from 'components/molecules/TabsBackground'
import { connect } from 'react-redux'
import * as selectors from 'store/page/selectors'

const Subscription = ({ subscriptions, faqs, clubs, page }) => {
  let groupedSubscriptions = []
  const [backgroundTransition, setBackgroundTransition] = useState(true)
  const [activeClub, setActiveClub] = useState(false)

  const groupBy = obj => {
    return obj.reduce(
      (result, item) => ({
        ...result,
        [item.clubs[0].name]: [...(result[item.clubs[0].name] || []), item],
      }),
      {},
    )
  }
  Object.entries(groupBy(subscriptions)).forEach((item, index) => {
    const name = item[0]

    const filteredClub = clubs.filter(item => item.name === name)
    groupedSubscriptions.push({})
    groupedSubscriptions[index].id = filteredClub[0].name
    groupedSubscriptions[
      index
    ].title = `${process.env.REACT_APP_HOST}${filteredClub[0].sub_logo.data.attributes.url}`
    groupedSubscriptions[
      index
    ].bg = `${process.env.REACT_APP_HOST}${filteredClub[0].sub_background.data.attributes.url}`
    groupedSubscriptions[index].alt = filteredClub[0].sub_logo.data.attributes.alt
    groupedSubscriptions[index].content = (
      <SubscriptionList key={index + item[0]} subList={item[1]} />
    )
  })

  return (
    <DefaultTemplate slug="subscription">
      <TabsBackground
        action={backgroundTransition}
        club={activeClub}
        content={groupedSubscriptions}
      />
      <Section>
        <Heading
          level={2}
          subTitle={page.data.attributes.overhead}
          children={page.data.attributes.name}
          subTitleColor="#888"
        />

        {groupedSubscriptions && groupedSubscriptions.length > 0 && (
          <TabsContent
            subHeading="Выбери клуб:"
            content={groupedSubscriptions}
            setBackgroundTransition={setBackgroundTransition}
            getActiveClub={setActiveClub}
            initialClub={activeClub}
          />
        )}
        <Questions list={faqs} subHeading="FAQ" />
      </Section>
    </DefaultTemplate>
  )
}

const mapStateToProps = state => ({
  page: selectors.selectContent(state),
  subscriptions: selectors.selectSubscriptions(state),
  faqs: selectors.selectFaqs(state),
  clubs: selectors.selectClubs(state),
})

export default connect(mapStateToProps)(Subscription)
