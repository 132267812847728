import React from 'react'
import { ReactComponent as Mari } from 'assets/svg/logo/mari-columbus.svg'
import { ReactComponent as Black } from 'assets/svg/logo/black.svg'
import { ReactComponent as Columbus } from 'assets/svg/logo/columbus.svg'
import { ReactComponent as KometaBlack } from 'assets/svg/logo/kometafit.svg'
import { ReactComponent as KometaFit } from 'assets/svg/logo/kometablack.svg'
import serialize from 'form-serialize'

export function getOptimizeImage(url, options = {}) {
  return `${process.env.REACT_APP_HOST}/${url.slice(0, 16)}/${options.width}x${
    options.height
  }/${url.slice(16)}/`
}

export function getDate(string, type) {
  const date = new Date(string + '+00:00')
  let options = {}

  switch (type) {
    case 'time':
      options = { timeZone: 'UTC', hour: '2-digit', minute: '2-digit' }
      break
    case 'hours':
      options = { timeZone: 'UTC', hour: '2-digit' }
      break
    case 'day':
      options = { timeZone: 'UTC', day: 'numeric', month: 'long' }
      break
    case 'weekday':
      options = { timeZone: 'UTC', weekday: 'long' }
      break
    default:
      options = { timeZone: 'UTC', day: '2-digit', month: '2-digit', year: 'numeric' }
  }

  const localeDate = date.toLocaleString('ru-RU', options)

  return type !== 'weekday' ? localeDate : localeDate.charAt(0).toUpperCase() + localeDate.slice(1)
}

export const isToday = compareDate => {
  const today = new Date()
  const someDate = new Date(compareDate)
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  )
}

export const lastDate = compareDate => {
  const today = new Date()
  const someDate = new Date(compareDate)
  return today > someDate
}

export function getLogo(name) {
  switch (name) {
    case 'Black':
      return <Black />
    case 'Columbus':
      return <Columbus />
    case 'Mari':
      return <Mari />
    case 'KometaBlack':
      return <KometaBlack />
    case 'KometaFit':
      return <KometaFit />
    default:
      return ''
  }
}

export function groupBy(obj, key) {
  return obj.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {},
  )
}

export function pluralize(number, one, two, five) {
  let n = Math.abs(number)
  n %= 100
  if (n >= 5 && n <= 20) {
    return five
  }
  n %= 10
  if (n === 1) {
    return one
  }
  if (n >= 2 && n <= 4) {
    return two
  }
  return five
}

export function getOffset(el) {
  const rect = el.getBoundingClientRect()
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY,
  }
}

export function scrollToY(targetY, speedY, easingY) {
  // speed: time in pixels per second
  // easing: easing equation to use
  let scrollY = window.scrollY
  let scrollTargetY = targetY || 0
  let speed = speedY || 2000
  let easing = easingY || 'easeInOutSine'
  let currentTime = 0

  var time = Math.max(0.001, Math.min(Math.abs(scrollY - scrollTargetY) / speed, 0.005))
  var easingEquations = {
    easeOutSine: function(pos) {
      return Math.sin(pos * (Math.PI / 2))
    },
    easeInOutSine: function(pos) {
      return -0.5 * (Math.cos(Math.PI * pos) - 1)
    },
    easeInOutQuint: function(pos) {
      if ((pos /= 0.5) < 1) {
        return 0.5 * Math.pow(pos, 5)
      }
      return 0.5 * (Math.pow(pos - 2, 5) + 2)
    },
  }

  // add animation loop
  function tick() {
    currentTime += 1 / 60

    var p = currentTime / time
    var t = easingEquations[easing](p)

    if (p < 1) {
      window.requestAnimationFrame(tick)
      window.scrollTo(0, scrollY + (scrollTargetY - scrollY) * t)
    } else {
      window.scrollTo(0, scrollTargetY)
    }
  }
  tick()
}

export function overflowBody(action) {
  const scrollBar = window.innerWidth - document.documentElement.clientWidth
  if (action) {
    document.body.style.overflow = 'hidden'
    document.body.style.paddingRight = scrollBar + 'px'
    let header = document.querySelector('.section--header')
    if (header) {
      header.style.paddingRight = scrollBar + 'px'
    }
  } else {
    document.body.style.overflow = ''
    document.body.style.paddingRight = ''
    let header = document.querySelector('.section--header')
    if (header) {
      header.style.paddingRight = ''
    }
  }
}

export function inRange(min, max, num) {
  return ((num - min) ^ (num - max)) < 0
}

export const getNormalizeJSON = (form, options = { hash: true }) => {
  const formJson = serialize(form, { hash: options.hash })

  if (!options.hash) return formJson

  const replaceValueForm = obj => {
    Object.keys(obj).forEach(key => {
      if (typeof obj[key] === 'object') replaceValueForm(obj[key])
      else {
        // eslint-disable-next-line
        obj[key] = typeof obj[key] === 'string' ? obj[key].trim() : obj[key]
      }
    })
  }

  replaceValueForm(formJson)

  const formJsonString = JSON.stringify(formJson)
    .replace(/"true"/g, true)
    .replace(/"false"/g, false)

  return JSON.parse(formJsonString)
}
