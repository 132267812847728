import { call, put } from 'redux-saga/effects'
import * as actions from '../actions'
import * as requests from 'api/page'

export default function*({ payload }) {
  try {
    const { slug } = payload
    const { data } = yield call(requests.getPage, { slug })

    yield put(actions.fetchPageSuccess(data))
  } catch (e) {
    yield put(actions.fetchPageFailure(e))
  }
}
