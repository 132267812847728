export const FETCH_TRAININGS_REQUEST = 'FETCH_TRAININGS_REQUEST'
export const FETCH_TRAININGS_SUCCESS = 'FETCH_TRAININGS_SUCCESS'
export const FETCH_TRAININGS_FAILURE = 'FETCH_TRAININGS_FAILURE'

export const fetchTrainingsRequest = today => ({
  type: FETCH_TRAININGS_REQUEST,
  today: today,
})

export const fetchTrainingsSuccess = trainings => ({
  type: FETCH_TRAININGS_SUCCESS,
  payload: { trainings },
})

export const fetchTrainingsFailure = error => ({
  type: FETCH_TRAININGS_FAILURE,
  error,
})
