import React, { useState, useLayoutEffect, useRef } from 'react'
import * as S from './styles'
import { ReactComponent as Arrow } from 'assets/svg/arrow.svg'

const Select = ({ options, value, allOption, onChange, ...props }) => {
  const node = useRef()
  const [isOpen, setIsOpen] = useState(false)
  const currentOption = options.find(({ key }) => key === value)

  const handleOptionClick = (key, label) => {
    onChange(key, label)
    setIsOpen(isOpen => !isOpen)
  }

  const handleClickOutside = e => {
    if (node.current.contains(e.target)) return
    setIsOpen(false)
  }

  useLayoutEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen])

  return (
    <S.Container {...props} ref={node} isOpen={isOpen}>
      <S.Line onClick={() => setIsOpen(!isOpen)}>
        <S.Value>{(currentOption && currentOption.label) || allOption}</S.Value>
        <S.Arrow>
          <Arrow />
        </S.Arrow>
      </S.Line>
      <S.Dropdown>
        {allOption && (
          <S.Option onClick={() => handleOptionClick(null, allOption)}>{allOption}</S.Option>
        )}
        {options.map(({ label, key }, index) => (
          <S.Option key={index + key} onClick={() => handleOptionClick(key, label)}>
            {label}
          </S.Option>
        ))}
      </S.Dropdown>
    </S.Container>
  )
}

export default Select
