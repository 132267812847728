import React, { useState } from 'react'
import routes from 'config/routes'
import * as S from './styles'
import Button from 'components/atoms/Button'
import Collapse from '@kunukn/react-collapse'

const Questions = ({ list, template, subHeading }) => {
  const [openItem, setOpenItem] = useState(null)

  return (
    <S.Wrapper template={template}>
      {template !== 'page' && (
        <div>
          <S.SubHeading>FAQ</S.SubHeading>
          <S.Caption level="2">Остались вопросы?</S.Caption>
          <Button type="border" to={routes.faq}>
            Все популярные вопросы
          </Button>
        </div>
      )}

      <S.Info template={template}>
        {list.map((item, index) => (
          <S.Item isOpen={openItem === index} key={index}>
            <S.Line onClick={() => setOpenItem(openItem !== index ? index : null)}>
              <S.Title dangerouslySetInnerHTML={{ __html: item.question }} />
              <S.Cross />
            </S.Line>
            <Collapse isOpen={openItem === index}>
              <S.Content dangerouslySetInnerHTML={{ __html: item.answer }} />
            </Collapse>
          </S.Item>
        ))}
      </S.Info>
    </S.Wrapper>
  )
}

export default Questions
