import React from 'react'
import Heading from 'components/atoms/Heading'
import * as S from './styles'

const SubscriptionItemGeneral = ({ subscriptionProps, cols }) => {
  return (
    <S.SubscriptionGeneral key={subscriptionProps.subscription.title} cols={cols - 1}>
      <Heading level="2" size={2}>
        {subscriptionProps.subscription.title}
      </Heading>
      <div dangerouslySetInnerHTML={{ __html: subscriptionProps.subscription.description }} />
    </S.SubscriptionGeneral>
  )
}

export default SubscriptionItemGeneral
