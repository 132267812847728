import React from 'react'
import * as S from './styles'
import * as selectors from 'store/page/selectors'
import { connect } from 'react-redux'

const FooterBottomLine = ({ header, socialLinks, footerDocuments }) => {
  let socialLinksDivided = { fit: [], black: [] }
  socialLinks.forEach(item => {
    if (item.slug.substr(item.slug.length - 5) === 'black') {
      socialLinksDivided.black.push(item)
    } else {
      socialLinksDivided.fit.push(item)
    }
  })

  return (
    <S.BottomLine>
      <S.BottomLineColumn className="footer-logo">
        {header.map(({ image }, index) => (
          <S.FooterLogo key={index}>
            <img
              src={`${process.env.REACT_APP_HOST}${image.data.attributes.url}`}
              alt={image.data.attributes.alt}
            />
          </S.FooterLogo>
        ))}
      </S.BottomLineColumn>

      <S.BottomLineColumn className="footer-social">
        <S.Social>
          <S.SocialItem>
            <p>Kometa.Black:</p>
            <S.SocialLinkWrap>
              {socialLinksDivided.black.map(({ title, url, image }, index) => (
                <S.SocialLink href={url} target="_blank" key={index}>
                  <img
                    src={`${process.env.REACT_APP_HOST}${image.data.attributes.url}`}
                    alt={title}
                  />
                </S.SocialLink>
              ))}
            </S.SocialLinkWrap>
          </S.SocialItem>
          <S.SocialItem>
            <p>Kometa.Fit:</p>
            <S.SocialLinkWrap>
              {socialLinksDivided.fit.map(({ title, url, image }, index) => (
                <S.SocialLink href={url} target="_blank" key={index}>
                  <img
                    src={`${process.env.REACT_APP_HOST}${image.data.attributes.url}`}
                    alt={title}
                  />
                </S.SocialLink>
              ))}
            </S.SocialLinkWrap>
          </S.SocialItem>
        </S.Social>
      </S.BottomLineColumn>

      <S.BottomLineColumn className="footer-rules">
        {footerDocuments.map(({ title }) => (
          <S.Rules key={title} dangerouslySetInnerHTML={{ __html: title }} />
        ))}
      </S.BottomLineColumn>
    </S.BottomLine>
  )
}

const mapStateToProps = state => ({
  header: selectors.selectHeader(state),
  socialLinks: selectors.selectSocialLinks(state),
  footerDocuments: selectors.selectFooterDocuments(state),
})

export default connect(mapStateToProps)(FooterBottomLine)
