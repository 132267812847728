import styled, { css } from 'styled-components/macro'
import Button from 'components/atoms/Button'
import { screen } from 'config/theme'
import { Link } from 'react-router-dom'

export const Header = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 1000;
  transition: top 0.15s ease;
`

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 40px;
  width: 100%;
  background: #000;
  border-bottom: 1px solid #282828;

  ${screen.lg} {
    padding: 40px 30px 40px 40px;
  }

  ${screen.md_sm} {
    padding: 13px 40px;
  }

  ${screen.sm} {
    padding: 13px 24px;
  }
`

export const LogoWrapper = styled(Link)`
  display: inline-flex;
  max-width: 185px;
  max-height: 50px;

  img {
    object-fit: contain;
  }

  ${screen.md} {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;

    img {
      max-width: 154px;
      width: 40vw;
    }
  }
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;

  ${screen.md} {
    width: 100%;
    justify-content: space-between;
  }
`

export const NavLinkDesktop = styled.div`
  ${screen.md_min} {
    margin-right: 50px;
  }
  @media(max-width:1289px) {
    display: none;
  }
  svg{
    transform: scale(0.7);
  }
  cursor: pointer;

  :hover div{
    -webkit-transform: scaleY(1);
	  -ms-transform: scaleY(1);
	  transform: scaleY(1);
  }
  font
`
export const NavSubmenu = styled.div`
  position: absolute;
  // top: 100%;
  // left: 0;
  // width: 100%;
  z-index: 100;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;

  -webkit-transform: scaleY(0);
	-ms-transform: scaleY(0);
	transform: scaleY(0);
  -webkit-transform-origin: 0 0;
	-ms-transform-origin: 0 0;
	transform-origin: 0 0;
  background: #000;

  padding: 14px;
  font-size: 14px;
  line-height: 30px;
  margin-left: -14px;
`


export const Phone = styled.a`
  font-weight: 700;

  ${screen.md_min} {
    margin-right: 50px;
  }

  ${screen.md} {
    display: none;
  }
`
export const Burger = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 67px;
  padding: 10px;
  margin-right: -10px;
  cursor: pointer;

  @media(min-width:1290px) {
    display: none;
  }
 
  span {
    display: block;
    height: 2px;
    background: #fff;
    transition: all 0.3s ease;
    margin-bottom: 10px;
    transform-origin: 100% 50%;

    &:nth-child(1) {
      width: calc(100% - 10px);
    }

    &:nth-child(2) {
      width: 100%;
    }

    &:nth-child(3) {
      width: calc(100% - 18px);
    }

    &:last-child {
      margin-bottom: 0;
    }

    ${({ menuOpen }) =>
      menuOpen &&
      css`
        &:nth-child(1) {
          width: 100%;
          transform: translate(-15%, -230%) rotate(-45deg);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          width: 100%;
          transform: translate(-15%, 230%) rotate(45deg);
        }
      `}
  }

  ${screen.lg_md} {
    margin-left: 30px;
  }

  ${screen.lg_min} {
    margin-left: 50px;

    &:hover {
      span {
        width: 100%;
      }
    }
  }

  ${screen.md} {
    transform: rotate(180deg) scaleY(-1) translateX(10px);
  }
`

export const ButtonWrapper = styled(Button)`
  min-width: auto;
  min-height: 40px;
  padding: 0 25px;
  font-size: 16px;

  ${screen.md_min} {
    margin-right: 20px;
  }

  ${screen.md} {
    display: none;
  }

  ${({ type }) =>
    type === 'outline' &&
    css`
      border-width: 1px;
    `}
`

export const MenuWrapper = styled.div`
  background: #000;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  transition: transform 0.5s ease-out, opacity 0.2s linear;
  opacity: 1;

  ${screen.md_min} {
    padding-top: 100px;
  }

  ${screen.md} {
    padding-top: 72px;
  }

  ${({ menuOpen }) =>
    !menuOpen &&
    css`
      opacity: 0;
      ${screen.md_min} {
        transform: translateX(100%);
      }
      ${screen.md} {
        transform: translateX(-100%);
      }
    `}
`

export const MenuContent = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  overflow: auto;

  ${screen.md_min} {
    justify-content: center;
    padding: 65px 0 40px;
    margin: auto;
  }

  ${screen.md} {
    flex-direction: column;
    padding: 30px 0;

    ${screen.md_sm} {
      padding: 8% 0;
    }

    @media (max-height: 700px) {
      display: block;
    }
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${screen.md_min} {
    padding: 0 120px;
  }

  ${screen.md_sm} {
    padding: 0 44px;
  }

  ${screen.md} {
    width: 100%;

    &:not(:last-of-type) {
      &:after {
        content: ' ';
        display: block;
        width: 100%;
        height: 1px;
        background: #282828;
        margin-top: 22px;

        ${screen.md_sm} {
          margin-top: 8%;
        }
      }
    }

    &:last-of-type {
      flex-grow: 1;
    }
  }

  ${screen.sm} {
    padding: 0 30px;
  }

  ${({ title }) =>
    title &&
    css`
      &:before {
        content: '${title}';
        letter-spacing: 0.3em;
        text-transform: uppercase;
        color: #888;
        font-weight: bold;

        ${screen.md_min} {
          font-size: 14px;
          margin-top: -30px;
          margin-bottom: 7px;
        }

        ${screen.md} {
          font-size: 12px;
          margin-bottom: 12px;
        }
      }
    `}
`

const linkHover = css`
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.5;
  }
`

export const ClubsLink = styled(Link)`
  width: 100%;
  display: flex;
  align-items: flex-start;

  ${screen.sm} {
    margin-bottom: 4px;
  }

  img {
    ${screen.sm} {
      height: 34px;
    }

    ${screen.md_sm} {
      height: 60px;
    }

    ${screen.lg_md} {
      height: 54px;
    }

    ${screen.lg_min} {
      height: 60px;
    }
  }

  &:not(:last-of-type) {
    ${screen.lg_md} {
      margin-bottom: 22px;
    }

    ${screen.lg_min} {
      margin-bottom: 30px;
    }
  }

  ${linkHover};
`

export const Menu = styled.div`
  font-weight: 700;
  line-height: 1.2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  ${screen.lg_min} {
    font-size: 44px;
  }

  ${screen.lg_md} {
    font-size: 32px;
  }

  ${screen.md_sm} {
    font-size: 32px;
    padding: 8% 0 40px;
  }

  ${screen.sm} {
    font-size: 20px;
    padding: 30px 0 40px;
  }
`

export const MenuLink = styled(Link)`
  ${linkHover};

  &:not(:last-of-type) {
    ${screen.md_min} {
      margin-bottom: 20px;
    }

    ${screen.md_sm} {
      margin-bottom: 16px;
    }

    ${screen.sm} {
      margin-bottom: 8px;
    }
  }
`

export const Social = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  ${screen.md_min} {
    margin-top: 70px;
    border-top: 1px solid #1d1d1d;
    padding-top: 30px;
  }

  ${screen.md} {
    width: initial;
    margin: 0 -18px;
    align-self: center;
    justify-content: center;
  }
`

export const SocialItem = styled.div`
  &:nth-child(2) {
    margin-left: 40px;
  }
`

export const SocialLinkWrap = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  margin-top: 10px;
`

export const SocialLink = styled.a`
  width: 26px;

  ${linkHover};

  &:not(:last-of-type) {
    ${screen.md_min} {
      margin-right: 18px;
    }
  }

  ${screen.md} {
    margin-right: 18px;
  }

  ${screen.xs} {
    margin-right: 18px;
  }
`

export const MobileContent = styled.div`
  ${screen.md_min} {
    display: none;
  }

  ${screen.md} {
    width: 100%;
    margin-top: auto;
    text-align: center;

    &:before {
      content: ' ';
      display: block;
      background: #282828;
      width: 100vw;
      margin-left: -40px;
      height: 1px;
      margin-bottom: 40px;
    }

    ${Phone} {
      display: block;
      text-align: center;
      margin: 20px 0;
    }

    ${ButtonWrapper} {
      display: inline-flex;
      width: 100%;
      margin-bottom: 20px;
      max-width: 320px;
    }
  }
`

export const LoginButton = styled.a`
  ${screen.md_min} {
    display: none;
  }

  ${screen.md} {
    display: inline-flex;
  }
`
