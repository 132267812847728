import React from 'react'
import * as S from './styles'
import * as selectors from 'store/page/selectors'
import { connect } from 'react-redux'

const HeaderMenu = ({ menuOpen, socialLinks, clubs, pages, header }) => {
  let socialLinksDivided = { fit: [], black: [] }
  socialLinks.forEach(item => {
    if (item.slug.substr(item.slug.length - 5) === 'black') {
      socialLinksDivided.black.push(item)
    } else {
      socialLinksDivided.fit.push(item)
    }
  })

  return (
    <S.MenuWrapper menuOpen={menuOpen}>
      <S.MenuContent>
        <S.Column title="Клубы">
          {clubs.map(({ name, logo, link_url }, index) => (
            <S.ClubsLink to={link_url} key={index}>
              <img
                src={`${process.env.REACT_APP_HOST}${logo.data.attributes.url}`}
                alt={logo.data.attributes.alt}
              />
            </S.ClubsLink>
          ))}
        </S.Column>
        <S.Column>
          <S.Menu>
            {pages.map(({ name, url }, index) => (
              <S.MenuLink to={url} key={index} dangerouslySetInnerHTML={{ __html: name }} />
            ))}
          </S.Menu>
          {header.map(({ description, buttons }) => (
            <S.MobileContent key={description}>
              {buttons.data.map(
                ({ attributes: { text, url } }, index) =>
                  buttons.data.length > index + 1 && (
                    <S.ButtonWrapper type="link" to={url} key={text}>
                      {text}
                    </S.ButtonWrapper>
                  ),
              )}
              <S.Phone
                href={`tel:${description.replace(/[^0-9.]/g, '')}`}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </S.MobileContent>
          ))}

          <S.Social>
            <S.SocialItem>
              <p>Kometa.Black:</p>
              <S.SocialLinkWrap>
                {socialLinksDivided.black.map(({ title, url, image }, index) => (
                  <S.SocialLink href={url} target="_blank" key={index}>
                    <img
                      src={`${process.env.REACT_APP_HOST}${image.data.attributes.url}`}
                      alt={title}
                    />
                  </S.SocialLink>
                ))}
              </S.SocialLinkWrap>
            </S.SocialItem>
            <S.SocialItem>
              <p>Kometa.Fit:</p>
              <S.SocialLinkWrap>
                {socialLinksDivided.fit.map(({ title, url, image }, index) => (
                  <S.SocialLink href={url} target="_blank" key={index}>
                    <img
                      src={`${process.env.REACT_APP_HOST}${image.data.attributes.url}`}
                      alt={title}
                    />
                  </S.SocialLink>
                ))}
              </S.SocialLinkWrap>
            </S.SocialItem>
          </S.Social>
        </S.Column>
      </S.MenuContent>
    </S.MenuWrapper>
  )
}

const mapStateToProps = state => ({
  socialLinks: selectors.selectSocialLinks(state),
  clubs: selectors.selectClubs(state),
  header: selectors.selectHeader(state),
  pages: selectors.selectPages(state),
})

export default connect(mapStateToProps)(HeaderMenu)
