import React from 'react'
import * as S from './styles'

const SubscriptionItemLast = ({ subscriptionProps }) => {
  const buttonData = subscriptionProps.currentMonth[0]
    ? subscriptionProps.currentMonth[0].attributes.button.data.attributes
    : subscriptionProps.subscriptionSubBlocks.button.data.attributes

  return (
    <S.SubscriptionItem
      template={subscriptionProps.template}
      key={subscriptionProps.subscription.title}
      isLastElement={subscriptionProps.isLastElement}
    >
      <React.Fragment>
        <S.SubscriptionItemColumn>
          <S.SubscriptionItemTitle
            isLastElement={subscriptionProps.isLastElement}
            dangerouslySetInnerHTML={{ __html: subscriptionProps.subscription.title }}
          />
          <S.SubscriptionItemContent
            dangerouslySetInnerHTML={{ __html: subscriptionProps.subscription.description }}
          />
        </S.SubscriptionItemColumn>

        <S.SubscriptionItemColumn>
          {subscriptionProps.subscription.sub_blocks.data.length > 1 && (
            <React.Fragment>
              <S.SelectItem
                key={subscriptionProps.filter}
                value={subscriptionProps.filter}
                options={subscriptionProps.monthOptions}
                onChange={value => subscriptionProps.setFilter(value)}
              />
              <S.SubscriptionItemPrice
                dangerouslySetInnerHTML={{
                  __html: subscriptionProps.currentMonth[0].attributes.price,
                }}
              />
            </React.Fragment>
          )}

          {subscriptionProps.subscription.sub_blocks.data.length === 1 && (
            <React.Fragment>
              {subscriptionProps.subscriptionSubBlocks.price_from_second_month && (
                <S.SubscriptionItemPrice
                  dangerouslySetInnerHTML={{
                    __html:
                      subscriptionProps.subscriptionSubBlocks.price_from_second_month +
                      '<em>со второго месяца</em>',
                  }}
                />
              )}
              <S.SubscriptionItemPrice
                dangerouslySetInnerHTML={{
                  __html:
                    subscriptionProps.subscriptionSubBlocks.price +
                    (subscriptionProps.subscriptionSubBlocks.price_from_second_month
                      ? '<em>в первый месяц</em>'
                      : ''),
                }}
              />
            </React.Fragment>
          )}

          {buttonData && (
            <S.SubscriptionItemButton type="link" to={buttonData.url} target="_blank">
              {buttonData.text}
            </S.SubscriptionItemButton>
          )}
        </S.SubscriptionItemColumn>
      </React.Fragment>
    </S.SubscriptionItem>
  )
}

export default SubscriptionItemLast
