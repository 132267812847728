import styled, { css } from 'styled-components/macro'
import { screen } from 'config/theme'

export const Popup = styled.div`
  position: relative;
  width: 100%;
  font-size: 16px;

  ${({ template }) =>
    template === 'policy'
      ? css`
          padding: 24px 20px;
          background: #414141;
        `
      : css`
          padding: 14px 20px;
          background: #1d1d1d;
        `}
`

export const Wrapper = styled.div`
  ${screen.sm_min} {
    display: flex;
    justify-content: center;
  }
`

export const Content = styled.div`
  align-self: center;
  margin-right: 20px;

  ${screen.sm_min} {
    margin-right: 36px;
  }

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`
export const Action = styled.div`
  border: 2px solid #fa2a47;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  transition: border 0.3s ease, color 0.3s ease;
  text-align: center;
  line-height: 1.2;
  color: #fff;
  background: transparent;
  border-color: #fff;
  font-size: 16px;
  min-height: 40px;
  padding: 0 42px;
  cursor: pointer;

  ${screen.md} {
    max-width: 180px;
    width: 100%;
    padding: 0 20px;
  }

  ${screen.md} {
    max-width: 100%;
    margin-top: 20px;
  }

  &:hover {
    border-color: #fa2a47;
    color: #fa2a47;
  }
`

export const Close = styled.div`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.7;
  }
`
