import styled, { keyframes } from 'styled-components/macro'
import { ReactComponent as PreloaderImage } from 'assets/svg/kometa-black.svg'

const pulseAnimation = keyframes`
  0% {
    stop-color: #212121;
  }

  50% {
    stop-color: #b7b7b7;
  }

  100% {
    stop-color: #212121;
  }
`

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Image = styled(PreloaderImage)`
  width: 240px;
  height: auto;
  max-width: 45%;

  stop {
    animation: ${pulseAnimation} 3s ease infinite;
  }
`
