import { createSelector } from 'reselect'
import { createLoadingSelector } from 'store/utils'
import * as actions from './actions'
import { getDate } from 'utils/helpers'

const groupTrainingsByDate = trainings =>
  trainings.reduce((acc, item) => {
    const date = getDate(item.startDate)

    return {
      ...acc,
      [date]: [...(acc[date] || []), item],
    }
  }, {})

const groupTrainingsByClubs = trainings =>
  trainings.reduce((acc, item) => {
    return {
      ...acc,
      [item.club.name]: [...(acc[item.club.name] || []), item],
    }
  }, {})

export const initialState = {
  trainings: [],
  trainingsToday: [],
}

const selectRoot = ({ trainings }) => trainings

export const selectTrainingsByDate = createSelector([selectRoot], ({ trainings }) =>
  groupTrainingsByDate(trainings),
)

export const selectTrainingsToday = createSelector(
  [selectTrainingsByDate],
  trainings => Object.values(trainings)[0] || [],
)

export const selectTrainingsByClub = createSelector([selectRoot], ({ trainings }) =>
  groupTrainingsByClubs(trainings),
)

export const selectTrainingsLoadingState = createLoadingSelector(actions.FETCH_TRAININGS_REQUEST)
