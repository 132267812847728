import React from 'react'
import * as S from './styles'
import { isMobile } from 'react-device-detect'
const today = new Date()

export const sliderParams = {
  navigation: {
    nextEl: '.arrow-next',
    prevEl: '.arrow-prev',
  },
  breakpoints: {
    1280: {
      slidesPerView: 7,
      slidesPerGroup: 7,
    },
    1080: {
      slidesPerView: 6,
      slidesPerGroup: 6,
    },
    940: {
      slidesPerView: 5,
      slidesPerGroup: 5,
    },
    768: {
      slidesPerView: 4,
      slidesPerGroup: 4,
    },
    600: {
      slidesPerView: 2,
      slidesPerGroup: 2,
    },
  },
  initialSlide: isMobile && window.innerWidth < 768 ? today.getDay() - 1 : 0,
  shouldSwiperUpdate: true,
  preloadImages: false,
  lazy: true,
  loadPrevNext: true,
  renderNextButton: () => <S.Arrow className="arrow-next" />,
  renderPrevButton: () => <S.Arrow className="arrow-prev" />,
}

export const selectList = [
  {
    name: 'filteredClub',
    allOption: 'Выбери клуб',
    content: [
      {
        label: 'Kometa Black',
        key: 'kometablack3',
      },
      {
        label: 'Kometa Columbus',
        key: 'kometacolumbus2',
      },
      {
        label: 'Kometa Mari',
        key: 'kometamari1',
      },
    ],
  },
  {
    name: 'time',
    allOption: 'Весь день',
    content: [
      {
        label: 'Утро',
        key: 'morning',
      },
      {
        label: 'День',
        key: 'afternoon',
      },
      {
        label: 'Вечер',
        key: 'evening',
      },
    ],
  },
]

export const timeAliases = {
  morning: hours => hours < 12,
  afternoon: hours => hours >= 12 && hours < 16,
  evening: hours => hours >= 16,
}
