import React from 'react'
import * as S from './styles'
import routes from 'config/routes'

const Policy = ({ action, template }) => (
  <React.Fragment>
    <S.Content>
      Мы используем файлы cookie. Подробнее в нашей политике по&nbsp;
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="/ckeditor_assets/attachments/22/privacy19082020.pdf"
      >
        обработке персональных данных
      </a>
      .
    </S.Content>
    <S.Action type="outline" onClick={() => action(template, 30)}>
      Все понятно
    </S.Action>
  </React.Fragment>
)

const OldSite = ({ action, template }) => (
  <React.Fragment>
    <S.Content>
      Это новая версия сайта. Перейти на старую можно по&nbsp;
      <a target="_blank" rel="noopener noreferrer" href="https://old.kometa.fit">
        ссылке
      </a>
      . Сообщить об ошибках можно&nbsp;
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={routes.feedback + '?' + window.location.pathname}
      >
        тут
      </a>
      .
    </S.Content>
    {action && (
      <S.Close onClick={() => action(template, 60)}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 1L19 19M19 1L1.00004 19" stroke="white" />
        </svg>
      </S.Close>
    )}
  </React.Fragment>
)

const RenderSwitch = ({ action, template }) => {
  switch (template) {
    case 'oldSite':
      return <OldSite action={action} template={template} />
    case 'policy':
      return <Policy action={action} template={template} />
    default:
      return false
  }
}

const InfoPopup = ({ action, template }) => {
  return (
    <S.Popup template={template}>
      <S.Wrapper>
        <RenderSwitch template={template} action={action} />
      </S.Wrapper>
    </S.Popup>
  )
}

export default InfoPopup
