import React, { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import * as S from './styles'
import Button from 'components/atoms/Button'
import ScrollLock, { TouchScrollable } from 'react-scrolllock'

const node = document.getElementById('modal')

const InfoModal = ({ info, setModalInfo }) => {
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    if (info !== null) {
      setIsActive(true)
    }
  }, [info])

  return (
    <S.Modal isActive={isActive}>
      <ScrollLock isActive={isActive} />
      <S.ModalOverlay
        onClick={() => {
          setIsActive(false)
          setModalInfo(null)
        }}
      />
      <TouchScrollable>
        <S.ModalContent>
          <S.Close
            onClick={() => {
              setIsActive(false)
              setModalInfo(null)
            }}
          />
          <S.ModalInfo>
            {info !== null && (
              <React.Fragment>
                <S.HeadingWrapper subTitle={info.subtitle}>{info.title}</S.HeadingWrapper>
                <S.Text dangerouslySetInnerHTML={{ __html: info.description }} />
                {/* {info.columns && (
                  <S.ModalFooter>
                    {info.columns.map(({ title, value }) => (
                      <React.Fragment>
                        {value && (
                          <S.Column key={title}>
                            <S.ColumnTitle>{title}</S.ColumnTitle>
                            <S.ColumnValue>{value}</S.ColumnValue>
                          </S.Column>
                        )}
                      </React.Fragment>
                    ))}
                  </S.ModalFooter>
                )}
                {info.clubsList && info.clubsList.length > 0 && (
                  <S.ModalFooter>
                    <span>Услуга доступна в клубах:</span>
                    <S.ClubsList>
                      {info.clubsList.map(({ attributes: { logo } }, index) => (
                        <img
                          src={`${process.env.REACT_APP_HOST}${logo.data.attributes.url}`}
                          alt={logo.data.attributes.alt}
                          key={index}
                        />
                      ))}
                    </S.ClubsList>
                  </S.ModalFooter>
                )} */}
                {info.button && (
                  <Button type="link" to={info.button.attributes.url}>
                    {info.button.attributes.text}
                  </Button>
                )}
              </React.Fragment>
            )}
          </S.ModalInfo>
        </S.ModalContent>
      </TouchScrollable>
    </S.Modal>
  )
}

const InfoModalPortal = props => createPortal(<InfoModal {...props} />, node)

export default InfoModalPortal
