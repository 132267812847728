import React from 'react'
import * as S from './styles'
import { Animated } from 'react-animated-css'
import 'animate.css'

const TabsBackground = ({ club, action, content }) => {
  return (
    <S.Wrapper>
      {content.map(item => (
        <Animated
          animationIn="fadeIn"
          animationOut="fadeOut"
          animationInDuration={400}
          animationOutDuration={400}
          isVisible={action && item.id === club}
        >
          <img src={item.bg} alt="" />
        </Animated>
      ))}
    </S.Wrapper>
  )
}

export default TabsBackground
