export const parseActionType = type => /(.*)_(REQUEST|SUCCESS|FAILURE)$/.exec(type)

export const createLoadingSelector = (...actions) => ({ loading }) =>
  actions.some(action => {
    const matches = parseActionType(action)
    if (!matches) return false

    const [, type] = matches
    return loading[type]
  })
