import React, { useLayoutEffect } from 'react'
import Heading from 'components/atoms/Heading'
import Button from 'components/atoms/Button'
import * as trainingsSelectors from 'store/trainings/selectors'
import * as pageSelectors from 'store/page/selectors'
import { bindActionCreators } from 'redux'
import * as actions from 'store/trainings/actions'
import { connect } from 'react-redux'
import * as S from './styles'
import { getDate } from 'utils/helpers'

const TrainingsList = ({ listTitle, trainings, trainingsLoading, fetchTrainings, clubId }) => {
  useLayoutEffect(() => {
    fetchTrainings('today')
  }, [fetchTrainings])

  const date = new Date()
  const filteredTrainings = trainings.filter(
    item => item.club.id === clubId && getDate(item.finishDate, 'hours') > date.getHours(),
  )
  return listTitle.map(({ title, description, buttons }) => (
    <S.Wrapper key={title} id={'trainings'}>
      <Heading margin="0 0 6vh 0" subTitle={title}>
        {description}
      </Heading>

      {filteredTrainings.length > 0 && (
        <S.Block isLoaded={trainingsLoading}>
          <S.Title>
            Сегодня — <span>{getDate(filteredTrainings[0].startDate, 'weekday')}</span> —{' '}
            {getDate(filteredTrainings[0].startDate, 'day')}
          </S.Title>
          {filteredTrainings.map(({ id, startDate, finishDate, name, serviceName }) => (
            <S.Line key={id}>
              <S.Item>
                {getDate(startDate, 'time')} – {getDate(finishDate, 'time')}
              </S.Item>
              <S.Item>{name}</S.Item>
              <S.Item>{serviceName}</S.Item>
              <S.Item>Запись в личном кабинете</S.Item>
            </S.Line>
          ))}
        </S.Block>
      )}
      {buttons.data.map(({ attributes: { text, url } }) => (
        <Button type="outline" to={url} key={text}>
          {text}
        </Button>
      ))}
    </S.Wrapper>
  ))
}

const mapStateToProps = state => ({
  listTitle: pageSelectors.selectScheduleListTitle(state),
  trainings: trainingsSelectors.selectTrainingsToday(state),
  trainingsLoading: trainingsSelectors.selectTrainingsLoadingState(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchTrainings: actions.fetchTrainingsRequest,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(TrainingsList)
