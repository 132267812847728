import React from 'react'
import DefaultTemplate from 'components/templates/Default'
import HeroBanner from 'components/organisms/HeroBanner'
import Advantages from 'components/molecules/Advantages'
import Questions from 'components/molecules/Questions'
import Section from 'components/atoms/Section'
import ClubsSection from 'components/molecules/ClubsSection'
import SubscriptionSectionGeneral from 'components/molecules/SubscriptionSectionGeneral'
import { connect } from 'react-redux'
import * as selectors from 'store/page/selectors'

const Home = ({ faqs }) => {

  return (
    <DefaultTemplate slug="main">
      <HeroBanner type="slider_main_top" template="main" />
      <Advantages />
      <ClubsSection id={'clubs'} />
      <HeroBanner
        headingContent={{ children: 'Пространство Kometa', subTitle: 'Услуги' }}
        type="slider_main_services"
        template="bottom"
        id={'services'}
      />
      <SubscriptionSectionGeneral id={'subscription'} type="outer"/>
      <Section>
        <Questions list={faqs} />
      </Section>
    </DefaultTemplate>
  )
}

const mapStateToProps = state => ({
  faqs: selectors.selectFaqs(state),
})

export default connect(mapStateToProps)(Home)
