import React from 'react'
import Modal from 'react-modal'
import * as S from './styles'
import SubscriptionSectionGeneral from '../SubscriptionSectionGeneral'

const SubscriptionPopup = ({ modalIsOpen, setIsOpen, setActiveTab, closeModalInitial }) => {
  function closeModal() {
    setIsOpen(false)
  }

  return (
    <Modal
      className={'cmp__modal'}
      overlayClassName={'cmp__modal-overlay'}
      ariaHideApp={false}
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Example Modal"
    >
      <S.Close onClick={() => closeModalInitial()} />
      <SubscriptionSectionGeneral
        id={'subscription'}
        type="inner"
        setActiveTab={setActiveTab}
        closeModal={closeModal}
      />
    </Modal>
  )
}

export default SubscriptionPopup
