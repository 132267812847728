import { initialState } from './selectors'
import * as actions from './actions'

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.FETCH_TRAININGS_SUCCESS:
      return {
        ...state,
        trainings: payload.trainings,
      }

    default:
      return state
  }
}
