import styled, { css, keyframes } from 'styled-components/macro'
import { screen } from 'config/theme'
import Section from 'components/atoms/Section'
/* stylelint-disable */

const showAnimation = keyframes`
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
`

export const Wrapper = styled(Section)`
  padding-bottom: 9%;
`

export const Block = styled.div`
  margin-bottom: 90px;
  transition: all 1s ease;

  ${({ isLoaded }) =>
    !isLoaded &&
    css`
      animation: ${showAnimation} 1s ease;
    `}
`

export const Title = styled.div`
  font-size: 20px;
  margin-bottom: 10px;

  span {
    font-weight: 700;
  }
`

export const Line = styled.div`
  padding: 16px 0;
  display: flex;
  align-items: center;
  margin: 0 -10px;

  &:not(:last-of-type) {
    border-bottom: 1px solid #414141;
  }
`

export const Item = styled.div`
  padding: 0 10px;

  :nth-child(1),
  :nth-child(2) {
    font-weight: 700;
    font-size: 28px;

    ${screen.md} {
      font-size: 24px;
    }

    ${screen.xs} {
      font-size: 20px;
    }
  }

  :nth-child(1) {
    width: 20%;

    ${screen.md} {
      width: 30%;
    }

    ${screen.xs} {
      width: 45%;
    }

    ._half & {
      width: 65%;
    }
  }

  :nth-child(2) {
    width: 46%;

    ${screen.md} {
      width: 56%;
    }

    ${screen.xs} {
      width: 55%;
    }

    ._half & {
      width: 65%;
    }
  }

  :nth-child(3) {
    width: 14%;

    ${screen.xs} {
      display: none;
    }

    ._half & {
      width: 35%;
    }
  }

  :nth-child(4) {
    width: 20%;
    color: #a2a2a2;

    ${screen.md} {
      display: none;
    }
  }

  :nth-child(2),
  :nth-child(4) {
    ._half & {
      display: none;
    }
  }
`
