import styled, { css } from 'styled-components/macro'
import { screen } from 'config/theme'

export const Wrapper = styled.a`
  background: #fa2a47;
  border: 2px solid #fa2a47;
  border-radius: 50px;
  min-height: 50px;
  position: relative;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 700;
  min-width: 360px;
  padding: 0 50px;
  transition: all 0.3s ease;
  text-align: center;
  line-height: 1.2;
  color: #fff;

  &:hover {
    color: #fa2a47;
    background: transparent;
  }

  ${screen.xs} {
    min-width: 100%;
    padding: 0 10px;
    font-size: 16px;
  }

  ._black & {
    background: #fff;
    color: #000;

    &:hover {
      color: #fff;
    }
  }
`

export const Text = styled.span`
  pointer-events: none;
`

export const ButtonOutline = styled(Wrapper)`
  background: transparent;
  border-color: #fff;

  &:hover {
    border-color: #fa2a47;
  }
`

export const Borderbox = styled.a`
  display: inline-block;
  background: transparent;
  padding: 0;
  border: none;
  border-bottom: 2px solid #fa2a47;
  font-weight: 700;
  line-height: 1.8;
  transition: all 0.3s ease;
  position: relative;

  ${Text} {
    color: #fff;
    display: inline-block;
    position: relative;
    transition: all 0.3s ease;
  }

  &:hover {
    ${Text} {
      color: #fa2a47;
    }
  }

  ${({ view }) =>
    view === 'black' &&
    css`
      border-color: #fff;

      &:hover {
        border-color: #fa2a47;
      }
    `}
`
