import page from './page/reducer'
import trainings from './trainings/reducer'
import loading from './loading/reducer'

const rootReducer = {
  page,
  trainings,
  loading,
}

export default rootReducer
