import styled, { css } from 'styled-components/macro'
import { screen } from 'config/theme'
import 'animate.css'

export const BannerPopup = styled.div`
  position: relative;
  margin: auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10vh 8vh;

  ${screen.sm} {
    padding: 55px 20px 20px;
  }
`

export const BannerImage = styled.div`
  position: relative;
  height: 100%;
  width: 100%;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`
export const BannerButton = styled.div`
  margin-top: 20px;
  text-align: center;

  a {
    margin: 20px 10px 0;
  }
`

export const Close = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #fff;
  position: absolute;
  transition: border 0.3s ease;
  z-index: 10;
  cursor: pointer;
  opacity: 1;
  top: 3vh;
  right: 3vh;

  ${screen.md} {
    top: 15px;
    right: 15px;
    transform: scale(0.7);
    transform-origin: top right;
  }

  &:after,
  &:before {
    content: ' ';
    width: 22px;
    height: 2px;
    background: #fff;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: 50% 50%;
    transition: transform 0.3s ease, background 0.3s ease;
  }

  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:hover {
    border-color: #fa2a47;

    &:after,
    &:before {
      background: #fa2a47;
    }
  }
`
