import React, { useState } from 'react'
import styled from 'styled-components/macro'
import Section from 'components/atoms/Section'
import Heading from 'components/atoms/Heading'
import Button from 'components/atoms/Button'
import SubscriptionList from 'components/molecules/SubscriptionList'
import TabsContent from 'components/molecules/TabsContent'
import { connect } from 'react-redux'
import * as selectors from 'store/page/selectors'

const ButtonWrapper = styled.div`
  text-align: center;
`
const SubscriptionSection = ({ subscriptions, clubs, id = '' }) => {
  let groupedSubscriptions = []
  const [backgroundTransition, setBackgroundTransition] = useState(true)
  const [activeClub, setActiveClub] = useState(
    window.location.pathname === '/clubs-black' ? 'Black' : 'Mari',
  )

  const groupBy = obj => {
    return obj.reduce(
      (result, item) => ({
        ...result,
        [item.clubs[0].name]: [...(result[item.clubs[0].name] || []), item],
      }),
      {},
    )
  }
  Object.entries(groupBy(subscriptions)).forEach((item, index) => {
    const name = item[0]

    const filteredClub = clubs.filter(item => item.name === name)
    groupedSubscriptions.push({})
    // console.log(filteredClub[0])
    groupedSubscriptions[index].id = filteredClub[0].name
    groupedSubscriptions[
      index
    ].title = `${process.env.REACT_APP_HOST}${filteredClub[0].sub_logo.data.attributes.url}`
    groupedSubscriptions[
      index
    ].bg = `${process.env.REACT_APP_HOST}${filteredClub[0].sub_background.data.attributes.url}`
    groupedSubscriptions[index].alt = filteredClub[0].sub_logo.data.attributes.alt
    groupedSubscriptions[index].content = (
      <SubscriptionList key={index + item[0]} subList={item[1]} />
    )
  })

  return (
    <React.Fragment>
      {groupedSubscriptions.length > 0 && (
        <Section
          background={
            activeClub === 'Black'
              ? 'linear-gradient(360deg, rgba(0, 0, 0, 0) 0%, #505050 100%);'
              : 'linear-gradient(180deg, #8E2930 0%, rgba(153, 37, 53, 0) 100%);'
          }
          backgroundText={activeClub === 'Black' ? 'gray' : 'red'}
          id={id}
          margin="60px auto "
        >
          <Heading level={3} children="Подписка на фитнес" subTitle="Выбери свою комету" />

          <TabsContent
            subHeading="Выбери клуб:"
            content={groupedSubscriptions}
            setBackgroundTransition={setBackgroundTransition}
            getActiveClub={setActiveClub}
            initialClub={activeClub}
          />
          <ButtonWrapper>
            <Button type="outline" to="/subscription">
              Посмотреть все абонементы
            </Button>
          </ButtonWrapper>
        </Section>
      )}
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  subscriptions: selectors.selectSubscriptions(state),
  clubs: selectors.selectClubs(state),
})

export default connect(mapStateToProps)(SubscriptionSection)
