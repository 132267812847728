export const theme = {}

export const screenSize = {
  xl: 1440,
  lg: 1280,
  md: 1024,
  sm: 768,
  xs: 568,
}

export const screen = {
  xl: `@media (max-width: ${screenSize.xl - 1}px)`,
  xl_max: `@media (max-width: ${screenSize.xl}px)`,
  xl_min: `@media (min-width: ${screenSize.xl}px)`,
  xl_lg: `@media (max-width: ${screenSize.xl - 1}px) and (min-width: ${screenSize.lg}px)`,

  lg: `@media (max-width: ${screenSize.lg - 1}px)`,
  lg_max: `@media (max-width: ${screenSize.lg}px)`,
  lg_min: `@media (min-width: ${screenSize.lg}px)`,
  lg_md: `@media (max-width: ${screenSize.lg - 1}px) and (min-width: ${screenSize.md}px)`,

  // md: `@media (max-width: ${screenSize.md - 1}px)`,
  md: `@media (max-width: ${screenSize.md}px)`,
  md_max: `@media (max-width: ${screenSize.md}px)`,
  md_min: `@media (min-width: ${screenSize.md}px)`,
  md_sm: `@media (max-width: ${screenSize.md - 1}px) and (min-width: ${screenSize.sm}px)`,

  sm: `@media (max-width: ${screenSize.sm - 1}px)`,
  sm_max: `@media (max-width: ${screenSize.sm}px)`,
  sm_min: `@media (min-width: ${screenSize.sm}px)`,
  sm_xs: `@media (max-width: ${screenSize.sm - 1}px) and (min-width: ${screenSize.xs}px)`,

  xs: `@media (max-width: ${screenSize.xs - 1}px)`,
  xs_max: `@media (max-width: ${screenSize.xs}px)`,
  xs_min: `@media (min-width: ${screenSize.xs}px)`,
}
