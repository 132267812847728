import styled from 'styled-components/macro'
import { screen } from '../../../config/theme'

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;

  ${screen.md_min} {
    top: 126px;
  }

  ${screen.md} {
    top: 70px;
  }

  img {
    width: 100%;
    background-size: cover;
    position: absolute;
    left: 0;
    top: 0;
  }
`
