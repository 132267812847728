import React from 'react'
import SubscriptionItem from 'components/atoms/SubscriptionItem'
import * as S from './styles'

const SubscriptionList = ({ subList, template }) => {
  const subscriptionData = template === 'main' ? subList.slice(0, 4) : subList

  return (
    <S.Grid template={template}>
      {subscriptionData.map((item, index) => (
        <SubscriptionItem
          key={index + item.title}
          item={item}
          template={template}
          isLastElement={subscriptionData.length - 1 === index}
          general={
            item.sub_blocks.data.length > 0
              ? item.sub_blocks.data[0].attributes.slug.indexOf('about') > 0
              : false
          }
          cols={subscriptionData.length}
        />
      ))}
    </S.Grid>
  )
}

export default SubscriptionList
