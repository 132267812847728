import React, { useState } from 'react'
import * as S from './styles'
import * as selectors from 'store/page/selectors'
import { connect } from 'react-redux'
import SpaceSectionPopup from 'components/molecules/SpaceSection/popup'
import { getOptimizeImage } from 'utils/helpers'
import LogoSpace from 'components/atoms/Space'

const SpaceSection = ({ spaces }) => {
  const [openItem, setOpenItem] = useState(null)
  const [currentBackground, setCurrentBackground] = useState(null)

  return spaces.map(({ title, description, image, smart_blocks }) => (
    <S.Wrapper key={title}>
      <S.SectionWrapper key={title}>
        <S.Preview>
          <S.HeadingWrapper>{title}</S.HeadingWrapper>
          <S.Text dangerouslySetInnerHTML={{ __html: description }} />
        </S.Preview>
        <S.List>
          {smart_blocks.data.map(
            ({ attributes: { name, title, description, uploaded_files } }, index) => (
              <S.Item key={index} onMouseEnter={() => setCurrentBackground(index)}>
                <S.Logo onClick={() => setOpenItem(index)} className={`-logo--${index}`}>
                  <LogoSpace name={name} active={false} />
                </S.Logo>
              </S.Item>
            ),
          )}
        </S.List>
      </S.SectionWrapper>
      {typeof smart_blocks.data[openItem] === 'object' && (
        <React.Fragment>
          <SpaceSectionPopup item={smart_blocks.data[openItem]} setOpenItem={setOpenItem} />
        </React.Fragment>
      )}

      <S.BackgroundWrapper>
        <S.Background
          background={getOptimizeImage(image.data.attributes.url, { width: '900', height: '505' })}
        >
          {smart_blocks.data.map(({ attributes: { uploaded_files } }, index) => (
            <S.BackgroundContent
              key={index}
              background={getOptimizeImage(uploaded_files.data[2].attributes.url, {
                width: '900',
                height: '505',
              })}
              isVisible={currentBackground === index}
            />
          ))}
        </S.Background>
      </S.BackgroundWrapper>
    </S.Wrapper>
  ))
}

const mapStateToProps = state => ({
  spaces: selectors.selectSpaces(state),
})

export default connect(mapStateToProps)(SpaceSection)
