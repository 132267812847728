import React, { useLayoutEffect, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import styled, { css } from 'styled-components/macro'
import Header from 'components/organisms/Header'
import Footer from 'components/organisms/Footer'
import * as selectors from 'store/page/selectors'
import { connect } from 'react-redux'
import Preloader from 'components/molecules/Preloader'
import InfoPopup from 'components/molecules/InfoPopup'
import ButtonUp from 'components/atoms/ButtonUp'
// import TagManager from 'react-gtm-module'

import { bindActionCreators } from 'redux'
import * as actions from 'store/page/actions'
import { screen } from 'config/theme'

import { Animated } from 'react-animated-css'
import 'animate.css'

import { getPageMetaTags } from 'api/page'
import BannerPopup from '../../molecules/BannerPopup'

const Wrapper = styled.div`
  position: relative;
`

const Content = styled.div`
  ${({ disableHeader }) =>
    !disableHeader &&
    css`
      ${screen.md_min} {
        padding-top: 126px;
      }

      ${screen.md} {
        padding-top: 72px;
      }
    `}
`

const DefaultTemplate = ({
  children,
  disableFooter = false,
  disableMapFooter = false,
  disableHeader = false,
  className,
  fetchPage,
  page,
  pageLoading,
  slug,
  header,
}) => {
  const [pageH1, setPageH1] = useState(false)
  useLayoutEffect(() => {
    fetchPage(slug)
  }, [fetchPage, slug])

  useEffect(() => {
    getPageMetaTags({ slug }).then(payload => {
      window.document.title = payload.data.data.attributes.title || ''
      setPageH1(payload.data.data.attributes.h1)
    })
  }, [slug])

  // Cookie Policy + Old Site
  const [cookies, setCookie] = useCookies(['policy', 'oldSite'])
  const useCookiesPopup = (cookie, time) => {
    const today = new Date()
    let expireTime = new Date()
    expireTime.setDate(today.getDate() + time)
    setCookie(cookie, true, { path: '/', expires: expireTime, sameSite: true })
  }

  // console.log(gtmId)
  //   if (header[0] && header[0].gtm_id !== null) {
  //     const tagManagerArgs = {
  //       gtmId: header[0].gtm_id,
  //     }
  //
  //     TagManager.initialize(tagManagerArgs)
  //   }

  return (
    <Wrapper>
      {pageH1 && (
        <h1 style={{ position: 'absolute', top: '-9999px', left: '-9999px' }}>{pageH1}</h1>
      )}
      <Animated
        animationIn="fadeIn"
        animationOut="fadeOut"
        animationInDuration={0}
        animationOutDuration={400}
        animationOutDelay={1000}
        isVisible={pageLoading}
        style={{ zIndex: 1001, position: 'fixed' }}
      >
        <Preloader />
      </Animated>

      {!disableHeader && <Header />}
      <Content disableHeader={disableHeader} className={className}>
        {children}
      </Content>

      <BannerPopup />

      {!disableFooter && <Footer disableMapFooter={disableMapFooter} />}
      <div style={{ position: 'sticky', bottom: '0', width: '100%', zIndex: 5 }}>
        {!cookies.policy && <InfoPopup action={useCookiesPopup} template="policy" />}
      </div>
      <ButtonUp />
    </Wrapper>
  )
}

const mapStateToProps = state => ({
  header: selectors.selectHeader(state),
  page: selectors.selectContent(state),
  pageLoading: selectors.selectPageLoading(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchPage: actions.fetchPageRequest,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(DefaultTemplate)
