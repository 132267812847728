import React from 'react'
import { YMaps, Map, Placemark, ZoomControl } from 'react-yandex-maps'
import * as S from './styles'
import * as selectors from 'store/page/selectors'
import { connect } from 'react-redux'

import FooterTopLine from './topLine'
import FooterBottomLine from './bottomLine'

const Footer = ({ props, clubs, disableMapFooter }) => {
  const filteredClubs = clubs.filter(item => item.link_url === window.location.pathname)
  return (
    <S.Wrapper>
      {!disableMapFooter && (
        <YMaps>
          <Map
            defaultState={{
              center: filteredClubs.length
                ? [filteredClubs[0].latitude, filteredClubs[0].longitude]
                : [55.69, 37.82],
              zoom: filteredClubs.length ? 16 : 11,
            }}
            instanceRef={ref => {
              ref && ref.behaviors.disable('scrollZoom')
            }}
            style={{ width: '100%', height: '60vh' }}
          >
            {(filteredClubs.length === 1 ? filteredClubs : clubs).map((pin, index) => (
              <Placemark
                key={index}
                geometry={[pin.latitude, pin.longitude]}
                onClick={() => (window.location.href = pin.link_url)}
                options={{
                  iconLayout: 'default#image',
                  iconImageHref: `${process.env.REACT_APP_HOST}${pin.svg_pin.data.attributes.url}`,
                  iconImageSize: pin.svg_pin_size,
                  iconOffset: [-5, -18],
                }}
              />
            ))}
            <ZoomControl options={{ float: 'right' }} />
          </Map>
        </YMaps>
      )}
      <FooterTopLine id="address" />
      <FooterBottomLine />
    </S.Wrapper>
  )
}

const mapStateToProps = state => ({
  clubs: selectors.selectClubs(state),
})

export default connect(mapStateToProps)(Footer)
