import styled, { css } from 'styled-components/macro'
import { screen } from 'config/theme'

export const Title = styled.span`
  display: inline-block;
  font-weight: 700;
  line-height: 1;

  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}

  ${({ size }) =>
    size === 2
      ? css`
          ${screen.md_min} {
            font-size: 40px;
          }
          ${screen.md_sm} {
            font-size: 30px;
          }
          ${screen.sm_max} {
            font-size: 24px;
          }
        `
      : css`
          ${screen.md_min} {
            font-size: 64px;
          }

          ${screen.md_sm} {
            font-size: 44px;
          }

          ${screen.sm_max} {
            font-size: 42px;
          }
          @media (max-width: 374px) {
            font-size: 36px;
          }
        `}

  span,
  strong {
    color: #fa2a47;
  }
`

export const SubTitle = styled.p`
  display: block;

  letter-spacing: 0.3em;
  text-transform: uppercase;
  line-height: 1.2;

  ${screen.xs_min} {
    font-size: 14px;
    margin-bottom: 8px;
  }

  ${screen.xs} {
    font-size: 12px;
    margin-bottom: 4px;
  }

  ${({ subTitleColor }) =>
    subTitleColor
      ? css`
          color: ${subTitleColor};
        `
      : css`
          color: #888;
        `}
`
