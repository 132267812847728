import React from 'react'
import DefaultTemplate from 'components/templates/Default'
import Heading from 'components/atoms/Heading'
import Text from 'components/atoms/Text'
import Section from 'components/atoms/Section'
import FeedbackForm from 'components/molecules/FeedbackForm'

const Feedback = history => {
  return (
    <DefaultTemplate disableMapFooter>
      <Section style={{ textAlign: 'center' }}>
        <Heading level={1} margin="0 0 30px 0" children="Нашел ошибку?" />
        <Text margin="0 auto 40px;" maxWidth="580px">
          Мы будем рады, если ты поможешь сделать наш новый сайт лучше. Напиши нам, если нашел
          ошибку или что-то не работает.
        </Text>
        <FeedbackForm />
      </Section>
    </DefaultTemplate>
  )
}

export default Feedback
