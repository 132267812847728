import React from 'react'
import { Switch, Route } from 'react-router'
import routes from 'config/routes'
import Home from 'components/pages/Home'
import ClubsFit from 'components/pages/ClubsFit'
import ClubsColumbus from 'components/pages/ClubsColumbus'
import ClubsBlack from 'components/pages/ClubsBlack'
import Services from 'components/pages/Services'
import Subscription from 'components/pages/Subscription'
import Faq from 'components/pages/Faq'
import Contacts from 'components/pages/Contacts'
import Schedule from 'components/pages/Schedule'
import Feedback from 'components/pages/Feedback'
import Error404 from 'components/pages/Error404'

const Routes = () => (
  <Switch>
    <Route path={routes.main} exact component={Home} />
    <Route path={routes.clubs_fit_mari} component={ClubsFit} />
    <Route path={routes.clubs_fit_columbus} component={ClubsColumbus} />
    <Route path={routes.clubs_black} component={ClubsBlack} />
    <Route path={routes.faq} component={Faq} />
    <Route path={routes.contacts} component={Contacts} />
    <Route path={routes.subscription} component={Subscription} />
    <Route path={routes.schedule} component={Schedule} />
    <Route path={routes.feedback} component={Feedback} />
    <Route path={routes.services} component={Services} />
    <Route path={routes.error404} component={Error404} />
    <Route path="" component={Error404} />
  </Switch>
)

export default Routes
