import React from 'react'
import * as S from './styles'
import Section from 'components/atoms/Section'
import Button from 'components/atoms/Button'
import * as selectors from 'store/page/selectors'
import { connect } from 'react-redux'

const TrialSection = ({ overlay, trialBlock, key }) =>
  trialBlock.map(({ description, image, buttons }) => (
    <Section
      backgroundImage={`${process.env.REACT_APP_HOST}${image.data.attributes.url}`}
      fullHeight
      overlay={overlay}
      key={description}
    >
      <S.Container key={0}>
        <S.HeadingWrapper level="2" margin="0 0 50px">
          {description}
        </S.HeadingWrapper>
        {buttons.data.map(({ attributes: { text, url } }) => (
          <Button type="link" to={url} key={text}>
            {text}
          </Button>
        ))}
      </S.Container>
    </Section>
  ))

const mapStateToProps = (state, { source }) => ({
  trialBlock: selectors.selectTrailBlock(source)(state),
})

export default connect(mapStateToProps)(TrialSection)
