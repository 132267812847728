import { createSelector } from 'reselect'

const entitiesByType = (data, entity, source) => {
  const dataObj = data.data.attributes
  switch (source) {
    case 'fragment':
      return dataObj.text_fragments.data.filter(({ attributes: { slug } }) => entity === slug)
    case 'fragment_part':
      return dataObj.text_fragments.data.filter(({ attributes: { slug } }) => slug.includes(entity))
    case 'clubs':
      return dataObj.clubs.data
    case 'pages':
      return dataObj.pages
    default:
      return data.included.filter(({ type }) => entity === type)
  }
}

const entitySelector = (entity, source) => data => {
  const entities = entitiesByType(data, entity, source)
  return source !== 'pages' ? entities.map(({ attributes }) => attributes) : entities
}

export const initialState = {
  content: {
    data: {
      attributes: {
        text_fragments: {
          data: [],
        },
        clubs: {
          data: [],
        },
        pages: [],
      },
    },
    included: [],
  },
  pageLoading: true,
}

const selectRoot = ({ page }) => page

export const selectContent = createSelector([selectRoot], ({ content }) => content)

export const selectPopUp = createSelector([selectContent], entitySelector('pop_up'))

export const selectBenefits = createSelector([selectContent], entitySelector('benefit'))

export const selectConcepts = createSelector([selectContent], entitySelector('concept'))

export const selectConceptsTitle = createSelector(
  [selectContent],
  entitySelector('concept_title', 'fragment'),
)

export const selectSubscriptions = createSelector([selectContent], entitySelector('subscription'))
export const selectFaqs = createSelector([selectContent], entitySelector('faq'))
export const selectSliders = createSelector([selectContent], entitySelector('slider'))

export const selectHeader = createSelector([selectContent], entitySelector('header', 'fragment'))

export const selectSocialLinks = createSelector(
  [selectContent],
  entitySelector('social_link', 'fragment_part'),
)

export const selectClubs = createSelector([selectContent], entitySelector('', 'clubs'))

export const selectPages = createSelector([selectContent], entitySelector('', 'pages'))

export const selectFooter = createSelector([selectContent], entitySelector('footer', 'fragment'))

export const selectContacts = createSelector(
  [selectContent],
  entitySelector('contacts', 'fragment'),
)

export const selectFooterDocuments = createSelector(
  [selectContent],
  entitySelector('footer_documents', 'fragment'),
)

export const selectScheduleListTitle = createSelector(
  [selectContent],
  entitySelector('schedule_list_title', 'fragment'),
)

export const selectTrailBlock = key =>
  createSelector([selectContent], entitySelector(key, 'fragment'))

export const selectServiceBlocks = createSelector([selectContent], entitySelector('service'))

export const selectGallery = createSelector([selectContent], entitySelector('gallery'))

export const selectSpaces = createSelector([selectContent], entitySelector('smart_zoning'))

export const selectError = key => createSelector([selectContent], entitySelector(key, 'fragment'))

export const selectPageLoading = createSelector([selectRoot], ({ pageLoading }) => pageLoading)
