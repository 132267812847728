import React from 'react'
import * as S from './styles'
import { useScrollPercentage } from 'react-scroll-percentage'
import { isMobileOnly } from 'react-device-detect'
import { ReactComponent as BackgroundText } from 'assets/img/kometa_text-bg.svg'
import { ReactComponent as BackgroundTextRed } from 'assets/img/kometa_text-bg-red.svg'
import { ReactComponent as BackgroundTextGray } from 'assets/img/kometa_text-bg-gray.svg'
import { ReactComponent as BackgroundTextAll } from 'assets/img/kometa_text-bg-gray-all.svg'

const BackgroundTextLogo = ({ type }) => {
  switch (type) {
    case 'all':
      return <BackgroundTextAll />
    case 'red':
      return <BackgroundTextRed />
    case 'gray':
      return <BackgroundTextGray />
    case 'black':
      return null
    default:
      return <BackgroundText />
  }
}

const Section = ({
  children,
  background,
  backgroundImage,
  backgroundSize,
  video,
  margin,
  fullHeight,
  fullWidth,
  className,
  overlay,
  id,
  backgroundText,
  style,
}) => {
  const [ref, percentage] = useScrollPercentage({ threshold: 0 })

  return (
    <S.Wrapper
      background={background}
      backgroundImage={backgroundImage}
      backgroundSize={backgroundSize}
      video={video}
      fullHeight={fullHeight}
      className={className}
      overlay={overlay}
      id={id}
      backgroundText={backgroundText}
      style={style}
    >
      <S.Content margin={margin} fullWidth={fullWidth}>
        {backgroundText && backgroundText !== 'false' && !isMobileOnly && (
          <S.BackgroundText offsetY={-(40 * percentage - 10).toPrecision(2)} ref={ref}>
            <BackgroundTextLogo type={backgroundText} />
          </S.BackgroundText>
        )}
        {children}
      </S.Content>
      {video && (
        <S.Video loop autoPlay muted>
          <source src={require(`assets/video/` + video + `.mp4`)} type="video/mp4" />
        </S.Video>
      )}
    </S.Wrapper>
  )
}

export default Section
