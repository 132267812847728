import styled, { css } from 'styled-components/macro'
import Heading from 'components/atoms/Heading'
import { screen } from 'config/theme'

export const HeadingWrapper = styled(Heading)`
  max-width: 50%;
`

export const Name = styled(Heading)`
  display: block;
  margin-bottom: 30px;
  padding-right: 10%;

  ${screen.xs_min} {
    margin-top: 140px;
  }

  ${screen.xs} {
    margin-top: 68px;
  }
`

export const ListWrapper = styled.div`
  ${screen.sm_min} {
    .masonry {
      margin-top: -140px;

      &__column {
        &:first-child > {
          *:first-child {
            margin-top: 190px;

            ${screen.md_min} {
              margin-top: 150px;
            }
          }

          *:nth-child(even) ${Name} {
            margin-top: 290px;
          }
        }

        &:last-child > {
          *:nth-child(odd) ${Name} {
            margin-top: 290px;
          }
        }
      }
    }
  }
`

export const Item = styled.div`
  padding: 20px 25px 30px;
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
  }

  ${({ type }) =>
    type === 'black'
      ? css`
          &:before {
            background: linear-gradient(
              47.18deg,
              #a92336 30.23%,
              rgba(0, 0, 0, 0.5) 56.77%,
              #30688f 85.51%
            );
            opacity: 0.65;
          }

          &:hover {
            &:before {
              opacity: 0.85;
            }

            button {
              border-color: #fff;

              span {
                color: #fff;
              }
            }
          }
        `
      : css`
          &:before {
            background: linear-gradient(224.1deg, rgba(116, 0, 0, 0.2) 35.89%, #000000 85.15%);
            opacity: 0.8;
          }

          &:hover {
            &:before {
              opacity: 1;
            }
          }
        `}
`

export const BackgroundWrapper = styled.div`
  position: relative;
`

export const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const Background = styled.div`
  ${({ type }) =>
    type === 'red' &&
    css`
      background: linear-gradient(180deg, #8e2930 0%, rgba(153, 37, 53, 0) 100%);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 780px;
    `}
`
