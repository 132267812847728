import React from 'react'
import * as S from './styles'

const SubscriptionItemSimple = ({ subscriptionProps }) => {
  const buttonData = subscriptionProps.currentMonth[0]
    ? subscriptionProps.currentMonth[0].attributes.button.data.attributes
    : subscriptionProps.subscriptionSubBlocks.button.data.attributes

  const subscriptionData =
    subscriptionProps.subscription.sub_blocks.data.length > 1
      ? subscriptionProps.currentMonth[0].attributes
      : subscriptionProps.subscriptionSubBlocks

  return (
    <S.SubscriptionItem
      template={subscriptionProps.template}
      className={`-type-${subscriptionProps.subscription.type}`}
      type={subscriptionProps.subscription.type}
      key={subscriptionProps.subscription.title}
      isLastElement={subscriptionProps.isLastElement}
    >
      <S.SubscriptionItemTitle
        dangerouslySetInnerHTML={{ __html: subscriptionProps.subscription.title }}
      />

      {subscriptionProps.subscription.sub_blocks.data.length > 1 && (
        <S.SelectItem
          key={subscriptionProps.filter}
          value={subscriptionProps.filter}
          options={subscriptionProps.monthOptions}
          onChange={value => subscriptionProps.setFilter(value)}
        />
      )}

      {subscriptionData.stock_price && (
        <S.SubscriptionItemPriceСross
          dangerouslySetInnerHTML={{
            __html: '<i>' + subscriptionData.stock_price + '</i> ',
          }}
        />
      )}

      {subscriptionData.price && (
        <S.SubscriptionItemPrice
          dangerouslySetInnerHTML={{
            __html: subscriptionData.price,
          }}
        />
      )}

      {subscriptionData.price_from_second_month && (
        <S.SubscriptionItemPrice
          dangerouslySetInnerHTML={{
            __html: subscriptionData.price_from_second_month,
          }}
        />
      )}

      <hr />

      <S.SubscriptionItemContent
        dangerouslySetInnerHTML={{ __html: subscriptionProps.subscription.description }}
      />

      {buttonData && (
        <S.SubscriptionItemButton type="link" to={buttonData.url} target="_blank">
          {buttonData.text}
        </S.SubscriptionItemButton>
      )}
    </S.SubscriptionItem>
  )
}

export default SubscriptionItemSimple
