import styled, { css } from 'styled-components/macro'
import { screen } from 'config/theme'
import Button from '../../atoms/Button'

export const DividerWrap = styled.div`
  position: relative;
  width: 100%;
`
export const DividerBg = styled.div`
  position: absolute;
  width: 33.33333333333%;
  // width: 50%;
  height: 100%;
  top: 0;
  ${({ background }) =>
    background &&
    css`
      background: ${background};
    `}

  ${screen.md} {
    display: none;
  }
`

export const DividerOverflow = styled.div`
  ${screen.md_min} {
    overflow: hidden;
    margin: 0 -70px;
    padding: 0 70px;
  }

  ${screen.md} {
    overflow: auto;
    padding: 0 30px;
    margin: 0 -30px;
  }
`

export const Divider = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10vh 0 8vh;

  ${screen.md_max} {
    display: block;
    margin: 50px 0;
  }
`

export const DividerItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  &:nth-child(1) {
    // padding-right: 10%;
    padding-right: 4%;
  }

  &:nth-child(2) {
    // padding-left: 10%;
    padding-left: 4%;
    padding-right: 4%;
  }
  &:nth-child(3) {
    padding-left: 4%;
  }

  // ${screen.md_sm} {
  //   max-width: 280px;
  //   padding: 0 !important;
  // }

  ${screen.md} {
    padding: 30px 25px 30px;
    margin-bottom: 48px;
    ${({ background }) =>
      background &&
      css`
        background: ${background};
      `}
  }
`

export const DividerImage = styled.div`
  margin-bottom: 45px;

  svg {
    height: 38px;
  }

  ${screen.lg_max} {
    svg {
      height: 26px;
    }
  }
  @media(max-width: 1530px){
    svg {
      height: 30px;
    }
  }
  @media(max-width: 1250px){
    svg {
      height: 20px;
    }
  }
  ${screen.md} {
    margin-bottom: 20px;

    svg {
      height: 20px;
    }
  }
`

export const DividerClubName = styled.div`
  font-size: 48px;
  line-height: 1.2;
  font-weight: 900;
  margin-bottom: 4px;

  ${screen.lg_max} {
    font-size: 36px;
  }

  ${screen.md} {
    font-size: 20px;
  }
`
export const DividerContent = styled.div`
  font-size: 20px;
  margin-bottom: 40px;
  max-width: 350px;

  ${screen.lg_max} {
    font-size: 18px;
  }

  ${screen.md} {
    font-size: 16px;
    margin-bottom: 20px;
  }
`

export const DividerPrice = styled.div`
  font-size: 48px;
  line-height: 1.15;
  font-weight: 700;
  margin-top: auto;
  margin-bottom: 44px;

  ${screen.lg_max} {
    font-size: 36px;
  }

  ${screen.md} {
    margin-top: 0;
    font-size: 24px;
  }
`

export const ButtonItemButton = styled(Button)`
  width: 100%;
  min-width: initial;
  max-width: 300px;

  ${screen.lg_min} {
    font-size: 32px;
    min-height: 74px;
  }

  // ${screen.lg_md} {
  //   font-size: 26px;
  //   min-height: 66px;
  // }
`
