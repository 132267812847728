import React from 'react'
import { connect } from 'react-redux'
import * as selectors from 'store/page/selectors'

import DefaultTemplate from 'components/templates/Default'
import ServicesList from 'components/molecules/ServicesList'
import TrainingsList from 'components/molecules/TraingingsList'
import TrialSection from 'components/organisms/TrialSection'
import ClubsSlider from 'components/molecules/ClubsSlider'
import HeroBanner from 'components/organisms/HeroBanner'
import SubscriptionSection from 'components/molecules/SubscriptionSection'
import Questions from 'components/molecules/Questions'
import Section from 'components/atoms/Section'

const ClubsFit = ({ faqs }) => {
  return (
    <DefaultTemplate slug="clubs_fit_mari">
      <HeroBanner type="slider_clubs_mari" template="secondary" />
      <ServicesList type="red" />
      <TrainingsList clubId={1} />
      <ClubsSlider />
      <TrialSection
        overlay="linear-gradient(242.47deg, rgba(0, 0, 0, 0.05) -1.54%, #000000 103.23%);"
        source="trial_block_fit"
      />
      <SubscriptionSection />
      <Section>
        <Questions list={faqs} />
      </Section>
    </DefaultTemplate>
  )
}

const mapStateToProps = state => ({
  faqs: selectors.selectFaqs(state),
})

export default connect(mapStateToProps)(ClubsFit)
