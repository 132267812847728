import React from 'react'
import Heading from 'components/atoms/Heading'
import * as S from './styles'
import * as selectors from 'store/page/selectors'
import { connect } from 'react-redux'
import { getOptimizeImage } from 'utils/helpers'

const ClubsSection = ({ concepts, conceptsTitle, id }) => {
  return (
    <S.SectionWrapper backgroundText id={id}>
      {conceptsTitle.map(({ title, description }) => (
        <Heading key={title} subTitle={title}>
          {description}
        </Heading>
      ))}
      {concepts.map(({ title, description, clubs }) => (
  
        <S.Block key={title}>
          <S.Title dangerouslySetInnerHTML={{ __html: title }} />
          <S.SubTitle dangerouslySetInnerHTML={{ __html: description }} />
          <S.Line>
            {clubs.data.map(
              ({
                attributes: {
                  name,
                  image,
                  logo,
                  address,
                  link_text,
                  link_url,
                  button: {
                    data: {
                      attributes: { text, url },
                    },
                  },
                },
              }) => (
                <S.ItemWrapper key={name}>
                  <S.Item>
                    <S.Background>
                      <picture>
                        <source
                          media="(min-width: 1080px)"
                          srcSet={getOptimizeImage(image.data.attributes.url, {
                            width: '1168',
                            height: '280',
                          })}
                        />
                        <img
                          src={getOptimizeImage(image.data.attributes.url, {
                            width: '567',
                            height: '300',
                          })}
                          alt={image.data.attributes.alt}
                        />
                      </picture>
                    </S.Background>
                    <S.Logo>
                      <img
                        src={`${process.env.REACT_APP_HOST}${logo.data.attributes.url}`}
                        alt={logo.data.attributes.alt}
                      />
                    </S.Logo>
                    <S.Text dangerouslySetInnerHTML={{ __html: address }} />
                    <div>
                      <S.ButtonWrapper type="route" to={url}>
                        {text}
                      </S.ButtonWrapper>
                      <S.ButtonWrapper type="border" to={link_url}>
                        {link_text}
                      </S.ButtonWrapper>
                    </div>
                  </S.Item>
                </S.ItemWrapper>
              ),
            )}
          </S.Line>
        </S.Block>
      ))}
    </S.SectionWrapper>
  )
}

const mapStateToProps = state => ({
  concepts: selectors.selectConcepts(state),
  conceptsTitle: selectors.selectConceptsTitle(state),
})

export default connect(mapStateToProps)(ClubsSection)
