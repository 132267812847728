import React from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { ThemeProvider } from 'styled-components/macro'
import store, { history } from 'store'
import { theme } from 'config/theme'
import Routes from 'routes'
import GlobalStyle from 'GlobalStyle'
import smoothscroll from 'smoothscroll-polyfill'
// import { loadReCaptcha } from 'react-recaptcha-v3'

const App = () => {
  smoothscroll.polyfill()

  return (
    <React.Fragment>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ThemeProvider theme={theme}>
            <Routes />
          </ThemeProvider>
        </ConnectedRouter>
      </Provider>
      <GlobalStyle />
    </React.Fragment>
  )
}

export default App
