import styled, { css } from 'styled-components/macro'
import Select from 'components/atoms/Select'
import Heading from 'components/atoms/Heading'
import { screen } from 'config/theme'

export const Overflow = styled.div`
  overflow: hidden;
`

export const SectionWrapper = styled.div`
  padding-top: 70px;
  margin: 0 40px;

  ${screen.md_max} {
    padding-top: 55px;
  }

  ${screen.sm} {
    margin: 0 24px;
  }
`

export const HeadingWrapper = styled(Heading)`
  margin-bottom: 70px;

  ${screen.sm} {
    margin-bottom: 50px;
  }
`

export const Filters = styled.div`
  position: relative;
  z-index: 3;
  display: block;

  ${screen.md} {
    display: flex;
    flex-direction: column;
    max-width: 70%;
    min-width: 260px;
  }
`

export const SelectItem = styled(Select)`
  ${screen.md_min} {
    margin-right: 60px;

    &:last-child {
      margin-right: 0;
    }
  }
`

export const Arrow = styled.div`
  width: 50px;
  height: 50px;
  position: absolute;
  top: -100px;
  border-radius: 50%;
  user-select: none;
  z-index: 1;
  color: #fa2a47;
  border: 2px solid currentcolor;
  transition: all 0.3s ease;
  cursor: pointer;

  ${screen.md_max} {
    top: -85px;
  }

  ${screen.xs_max} {
    width: 33px;
    height: 33px;
    top: 4px;
  }

  /* stylelint-disable */

  &:before {
    content: ' ';
    height: 16px;
    width: 16px;
    border-left: 2px solid currentcolor;
    border-bottom: 2px solid currentcolor;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-35%, -50%) rotate(45deg);
    transition: all 0.3s ease;
    opacity: 1 !important;

    ${screen.xs_max} {
      width: 10px;
      height: 10px;
    }
  }

  &:active {
    background-color: currentcolor;

    &:before {
      border-color: #fff;
    }
  }

  @media screen and (cursor: pointer) {
    &:hover {
      background-color: currentcolor;

      &:before {
        border-color: #fff;
      }
    }
  }

  &.swiper-button-disabled {
    pointer-events: none;
    color: #d1d1d1;
  }

  &.arrow-prev {
    right: 72px;
    left: auto;
    transform: none;

    ${screen.xs_max} {
      left: 5px;
      right: auto;
    }
  }

  &.arrow-next {
    right: 6px;
    transform: rotate(180deg);

    ${screen.xs_max} {
      left: auto;
      right: 10px;
    }
  }
`

export const Column = styled.div`
  padding: 0 5px;
  transition: all 0.5s ease;
  transition-property: transform, background;

  &.swiper-slide-active {
    //background: #141c31;
  }
`

export const Content = styled.div``

export const Caption = styled.div`
  font-size: 16px;

  ${screen.xs_max} {
    text-align: center;
  }
`

export const Day = styled.p`
  font-weight: 700;

  ${screen.sm} {
    font-size: 20px;
    line-height: 1;
  }
`

export const DayName = styled.p`
  margin-bottom: 20px;

  ${screen.sm} {
    font-size: 20px;
    margin-bottom: 40px;
  }
`

const textTransition = 'transition: all 0.3s ease;'

export const Club = styled.p`
  color: #a2a2a2;
  font-size: 14px;
  margin-bottom: 12px;
  ${textTransition};
`

export const Time = styled.p`
  font-size: 20px;
  font-weight: 700;
  color: #fa2a47;
  ${textTransition};

  ${screen.sm} {
    font-size: 24px;
  }
`

export const Name = styled.p`
  font-size: 18px;
  font-weight: 700;
  margin-top: auto;
  margin-bottom: 8px;
  ${textTransition};
`

export const Class = styled.p`
  ${textTransition};
`

export const Item = styled.div`
  min-height: 260px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #fff;
  padding: 26px 17px;
  color: #000;
  margin-bottom: 11px;
  cursor: pointer;
  transition: all 0.3s ease;

  ${screen.sm} {
    padding: 26px 24px;
  }

  &:last-child {
    margin-bottom: 0;
  }


  ${({ isToday }) =>
    isToday &&
    css`
      background: #AB1E31;
      ${Club}, ${Time}, ${Name}, ${Class} {
        color: #fff;
      }
    `}


  ${({ isLastDate }) =>
    isLastDate &&
    css`
      background: #414141;
      ${Club}, ${Time}, ${Name}, ${Class} {
        color: #888888;
      }
    `}

  &:active {
    background: #FA2A47;
  }

  &:active {
    ${Club}, ${Time}, ${Name}, ${Class} {
      color: #fff;
    }
  }

  @media screen and (cursor: pointer) {
    &:hover {
      background: #FA2A47;
    }

    &:hover {
      ${Club}, ${Time}, ${Name}, ${Class} {
        color: #fff;
      }
    }
  }
`

export const SliderWrapper = styled.div`
  position: relative;
  padding-top: 90px;
  margin-top: -35px;
  z-index: 2;

  ${screen.sm} {
    padding-top: 24px;
    margin-top: 0;
  }
`

export const Wrapper = styled.div`
  width: calc(100% + 10px);
  margin-left: -5px;
  padding-bottom: 65px;
  opacity: 0;
  transition: all 0.3s ease;

  ${({ isLoaded }) =>
    isLoaded &&
    css`
      opacity: 1;
    `}

  .swiper-container {
    overflow: visible;
  }
`
