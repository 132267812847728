import { initialState } from './selectors'
import * as actions from './actions'

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.FETCH_PAGE_REQUEST:
      return {
        ...state,
        pageLoading: true,
      }

    case actions.FETCH_PAGE_SUCCESS:
      return {
        ...state,
        content: payload.page,
        pageLoading: false,
      }

    case actions.FETCH_PAGE_FAILURE:
      if (process.env.NODE_ENV === 'development') {
        return (window.location.href = '/404')
      }

      break

    default:
      return state
  }
}
