import React from 'react'
import * as S from './styles'

const Heading = ({ level, children, subTitle, subTitleColor, ...props }) => (
  <S.Title as={level && `h${level}`} {...props}>
    {subTitle && (
      <S.SubTitle subTitleColor={subTitleColor} dangerouslySetInnerHTML={{ __html: subTitle }} />
    )}
    <div dangerouslySetInnerHTML={{ __html: children }} />
  </S.Title>
)

export default Heading
