import React from 'react'
import Section from 'components/atoms/Section'
import Heading from 'components/atoms/Heading'
import { getLogo } from 'utils/helpers'
import * as selectors from 'store/page/selectors'
import * as S from './styles'
import { connect } from 'react-redux'

const SubscriptionSection = ({
  id = '',
  type,
  setActiveTab,
  closeModal,
  concepts,
  conceptsTitle,
  clubs,
}) => {
  function activeTab(club) {
    setTimeout(() => {
      setActiveTab(club)
      closeModal()
    }, 350)
  }

  return (
    <S.DividerWrap>
      <S.DividerBg
        style={{ right: 0 }}
        background={'linear-gradient(180deg, #8E2930 0%, rgba(153, 37, 53, 0) 100%)'}  
      />
      <S.DividerBg
        style={{ left: 0 }}
        background={'linear-gradient(360deg, rgb(0,0,0,0) 0%, #2b2b2b 30%, #505050 100%)'}
        
      />

      
      
      

      <Section
        style={{}}
        id={id}
        margin="60px auto 0"
        fullWidth="inherit"
        backgroundText={`${type === 'inner' ? false : 'all'}`}
      >
        {type === 'inner' ? (
          <Heading level={3} children="Выбери клуб" />
        ) : (
          <Heading level={3} children="Подписка на фитнес" subTitle="Выбери свою комету" />
        )}
        <S.DividerOverflow>
          <S.Divider>
            <S.DividerItem
              background={'linear-gradient(360deg, rgba(0, 0, 0, 0) 0%, #505050 100%)'}
            >
              <S.DividerImage>{getLogo('KometaFit')}</S.DividerImage>
              <S.DividerContent dangerouslySetInnerHTML={{ __html: `${clubs.filter(item => item)[0]?.address}` }}>
              </S.DividerContent>
              <S.DividerPrice>{`от ${concepts.filter(item => item.slug === 'black')[0]?.price ||
                3075} ₽/мес.`}</S.DividerPrice>
              {type === 'inner' ? (
                <S.ButtonItemButton type="link" onClick={() => activeTab('Black')}>
                  Выбрать
                </S.ButtonItemButton>
              ) : (
                <S.ButtonItemButton type="link" to="/subscription#Black">
                  Выбрать
                </S.ButtonItemButton>
              )}
            </S.DividerItem>

            <S.DividerItem
              background={'linear-gradient(180deg, #8E2930 0%, rgba(153, 37, 53, 0) 100%)'}
            >
              <S.DividerImage>{getLogo('KometaBlack')}</S.DividerImage>
              <S.DividerClubName>MARI</S.DividerClubName>

              <S.DividerContent dangerouslySetInnerHTML={{ __html: `${clubs.filter(item => item)[1]?.address}` }}>
              </S.DividerContent>
              <S.DividerPrice>{`от ${concepts.filter(item => item.slug === 'fit')[0]?.price ||
                1550} ₽/мес.`}</S.DividerPrice>
              {type === 'inner' ? (
                <S.ButtonItemButton type="link" onClick={() => activeTab('Mari')}>
                  Выбрать
                </S.ButtonItemButton>
              ) : (
                <S.ButtonItemButton type="link" to="/subscription#Mari">
                  Выбрать
                </S.ButtonItemButton>
              )}
            </S.DividerItem>

            <S.DividerItem
              background={'linear-gradient(180deg, #8E2930 0%, rgba(153, 37, 53, 0) 100%)'}
            >
              <S.DividerImage>{getLogo('KometaBlack')}</S.DividerImage>

              <S.DividerClubName>COLUMBUS</S.DividerClubName>

              <S.DividerContent dangerouslySetInnerHTML={{ __html: `${clubs.filter(item => item)[2]?.address}` }}>
              </S.DividerContent>
              <S.DividerPrice>{`от ${concepts.filter(item => item.slug === 'Columbus')[0]?.price ||
                1550} ₽/мес.`}</S.DividerPrice>

              {type === 'inner' ? (
                <S.ButtonItemButton type="link" onClick={() => activeTab('Columbus')}>
                  Выбрать
                </S.ButtonItemButton>
              ) : (
                <S.ButtonItemButton type="link" to="/subscription#Columbus">
                  Выбрать
                </S.ButtonItemButton>
              )}
            </S.DividerItem>
            </S.Divider>
        </S.DividerOverflow>
      </Section>
    </S.DividerWrap>
  )
}

const mapStateToProps = state => ({
  concepts: selectors.selectConcepts(state),
  conceptsTitle: selectors.selectConceptsTitle(state),
  clubs: selectors.selectClubs(state),
})

export default connect(mapStateToProps)(SubscriptionSection)
