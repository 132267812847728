import React from 'react'
import DefaultTemplate from 'components/templates/Default'
import ErrorInfo from 'components/organisms/ErrorInfo'

const Error404 = () => (
  <DefaultTemplate disableFooter>
    <ErrorInfo source="page_404" />
  </DefaultTemplate>
)

export default Error404
