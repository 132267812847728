const routes = {
  main: '/',
  clubs_fit_mari: '/clubs-fit-mari',
  clubs_fit_columbus: '/clubs-fit-columbus',
  clubs_black: '/clubs-black',
  subscription: '/subscription',
  services: '/services',
  error404: '/404',
  schedule: '/schedule',
  faq: '/faq',
  contacts: '/contacts',
  feedback: '/feedback',
}

module.exports = routes
