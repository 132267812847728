import Cookies from 'js-cookie'

export const getCookies = key => {
  return Cookies.get(key)
}

export const setCookies = (key, value, params = {}) => {
  return Cookies.set(key, value, params)
}

export const removeCookies = key => {
  return Cookies.remove(key)
}
