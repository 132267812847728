import React, { useState } from 'react'
import * as S from './styles'
import { Animated } from 'react-animated-css'
import 'animate.css'

const ButtonUp = () => {
  const [isScrollUp, setIsScrollUp] = useState(false)

  const handleScroll = e => {
    window.pageYOffset > window.innerHeight ? setIsScrollUp(true) : setIsScrollUp(false)
  }
  document.addEventListener('scroll', handleScroll)

  return (
    <Animated
      animationIn="fadeIn"
      animationOut="fadeOut"
      animationInDuration={400}
      animationOutDuration={400}
      isVisible={isScrollUp}
      style={{ zIndex: 1, position: 'relative' }}
      className="button-up"
    >
      <S.Wrapper onClick={() => window.scroll({ top: 0, left: 0, behavior: 'smooth' })}>
        <svg width="29" height="17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M28.1 15.54L14.87 1.68 1.64 15.54" stroke="#D1D1D1" strokeWidth="2" />
        </svg>
      </S.Wrapper>
    </Animated>
  )
}

export default ButtonUp
