import React, { useState } from 'react'
import * as S from './styles'
import { getNormalizeJSON } from 'utils/helpers'
import axios from 'axios'
import { loadRecaptcha, ReCaptcha } from 'react-recaptcha-v3'
import { Animated } from 'react-animated-css'
import 'animate.css'

loadRecaptcha('6LcJld4UAAAAAHLn6msDX7wtJM6ZTwu_v_tFHypn')

const FeedbackForm = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [formResponse, setFormResponse] = useState({ succsess: false, error: false })
  const [recaptcha, setRecaptcha] = useState(false)

  const URL = 'https://kometa.staging.digitalizm.com/api/v1/feedbacks'

  const verifyCallback = recaptchaToken => {
    setRecaptcha(recaptchaToken)
  }

  const handleFormResponse = (form, type) => {
    setIsLoading(false)
    form.reset()
    setFormResponse({ ...formResponse, [type]: true })
  }

  const handleSubmit = event => {
    event.preventDefault()
    const form = event.target
    const data = getNormalizeJSON(form)

    setIsLoading(true)
    axios
      .post(URL, data)
      .then(function(response) {
        setTimeout(() => {
          handleFormResponse(form, 'succsess')
        }, 1000)
      })
      .catch(function(error) {
        setTimeout(() => {
          handleFormResponse(form, 'error')
        }, 1000)
      })
  }

  return (
    <S.FormWrapper>
      {!formResponse.succsess && !formResponse.error && (
        <ReCaptcha
          sitekey="6LcJld4UAAAAAHLn6msDX7wtJM6ZTwu_v_tFHypn"
          action="feedback"
          verifyCallback={verifyCallback}
        />
      )}

      <Animated
        animationIn="fadeIn"
        animationOut="fadeOut"
        animationInDuration={300}
        animationOutDuration={300}
        isVisible={isLoading}
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 10 }}
      >
        <S.Spinner>
          <svg width="58" height="58" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5 28.579C5 15.504 15.692 5 29 5s24 10.504 24 23.579C53 34.5 50.839 39.865 47.199 44"
              stroke="url(#paint0_linear)"
              strokeWidth="4"
              strokeMiterlimit="10"
            />
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="2.725"
                y1="24.083"
                x2="55.265"
                y2="24.083"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#fff" stopOpacity="0" />
                <stop offset="1" stopColor="#888" />
              </linearGradient>
            </defs>
          </svg>
        </S.Spinner>
      </Animated>

      <Animated
        animationIn="fadeIn"
        animationOut="fadeOut"
        animationInDuration={300}
        animationOutDuration={300}
        isVisible={!formResponse.succsess && !formResponse.error}
      >
        <S.Form onSubmit={handleSubmit} action="/">
          <S.Field>
            <S.Input type="email" name="email" placeholder="E-mail" required />
            <S.Label>E-mail:</S.Label>
          </S.Field>

          <S.Field>
            <S.Textarea
              required
              type="textarea"
              name="message"
              placeholder="Сообщение"
              rows="4"
              cols="50"
            />
            <S.Label>Сообщение</S.Label>
          </S.Field>
          <input hidden="hidden" name="topic" value="topic" readOnly />
          <input
            id="g-recaptcha-response"
            type="hidden"
            name="g-recaptcha-response"
            value={recaptcha}
          />
          <input
            hidden="hidden"
            name="last_page"
            value={window.location.search.substring(1)}
            readOnly
          />

          <S.Submit>Отправить</S.Submit>
        </S.Form>
      </Animated>

      <Animated
        animationIn="fadeIn"
        animationOut="fadeOut"
        animationInDuration={300}
        animationOutDuration={10}
        isVisible={formResponse.succsess || formResponse.error}
        style={{
          position: 'absolute',
          top: '40px',
          left: '50%',
          transform: 'translateX(-50%)',
          maxWidth: '100%',
          width: '360px',
        }}
      >
        <S.Response>
          {formResponse.succsess && (
            <React.Fragment>
              <h3>Спасибо за помощь, твое сообщение отправлено</h3>
              <p>Ты можешь сообщить нам об еще одной ошибке, нажав на кнопку ниже.</p>
            </React.Fragment>
          )}
          {formResponse.error && (
            <React.Fragment>
              <h3>Сообщение не удалось отправить</h3>
              <p>Попробуйте отправить своё сообщение позже.</p>
            </React.Fragment>
          )}
          <S.Resend onClick={() => setFormResponse({ succsess: false, error: false })}>
            Сообщить об ошибке
          </S.Resend>
        </S.Response>
      </Animated>
    </S.FormWrapper>
  )
}

export default FeedbackForm
