import { call, put } from 'redux-saga/effects'
import * as actions from '../actions'
import * as requests from 'api/trainings'

function formatDate(date) {
  return date.toLocaleString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })
}

export default function*(props) {
  try {
    let startDateObj = new Date()

    if (!props.today) {
      // get the weekday number
      let day = startDateObj.getDay()
      // start from the beginning of the week
      let beginningOfWeek = startDateObj.getDate() - day + (day === 0 ? -6 : 1)
      startDateObj = new Date(startDateObj.setDate(beginningOfWeek))
    }

    let endDateObj = new Date()
    endDateObj.setDate(endDateObj.getDate() + 14)

    const startDate = formatDate(startDateObj)
    const endDate = formatDate(endDateObj)

    const { data } = yield call(requests.getTrainings, { startDate, endDate })

    yield put(actions.fetchTrainingsSuccess(data.data))
  } catch (e) {
    yield put(actions.fetchTrainingsFailure(e))
  }
}
