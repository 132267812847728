import styled, { css } from 'styled-components/macro'
import { screen } from '../../../config/theme'

export const Wrapper = styled.nav`
  ${screen.lg_min} {
    margin-top: 70px;
  }

  ${screen.lg_md} {
    margin-top: 50px;
  }

  ${screen.md_sm} {
    margin-top: 50px;
  }

  ${screen.sm} {
    margin-top: 20px;
  }
`

export const Arrow = styled.div`
  position: absolute;
  right: 30px;
  top: 33px;
  display: none;

  ${screen.sm} {
    display: block;
  }

  svg {
    fill: #fa2a47;
    transition: transform 0.15s ease-out;
  }
`

export const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  left: 0;
  z-index: 10;
  width: 100%;
  box-sizing: border-box;
  // display: none;

  ${screen.sm} {
    flex-direction: column;
    align-items: flex-start;
    clip-path: polygon(0 0, 100% 0, 100% 70px, 0 70px);
    position: absolute;
    z-index: 10;
    left: 0;
    width: 100%;

    transition: 0.15s ease-out;
    padding: 30px 30px 10px;

    ${screen.sm_xs} {
      left: 40px;
      max-width: 370px;
    }

    ${({ isFocused }) =>
      isFocused &&
      css`
        background: rgba(0, 0, 0, 0.8);
        clip-path: polygon(0% 0px, 100% 0px, 100% 100%, 0% 100%);

        svg {
          transform: rotateZ(180deg);
        }

        ${HeaderItem} {
          border-bottom-color: transparent;
        }
      `};
  }
`

const HeaderItemHover = `border-color: #fff;`

export const HeaderItem = styled.span`
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  text-align: center;
  color: #fff;
  background-color: transparent;
  appearance: none;
  border: none;
  border-bottom: 2px solid transparent;
  transition: 0.15s ease-out;
  cursor: pointer;

  /* stylelint-disable */
  margin-left: 1px;
  /* stylelint-enable */

  display: flex;
  align-items: center;

  img {
    max-height: 38px;
  }

  ${screen.xs_min} {
    &:not(:last-child) {
      margin-right: 5%;
    }
  }

  ${screen.sm} {
    width: 100%;
    margin-bottom: 20px;
    border-bottom-width: 1px;

    img {
      height: 34px;
    }

    ${({ isActive }) =>
      isActive &&
      css`
        order: -1;
      `};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      ${HeaderItemHover};
      pointer-events: none;
    `};

  &:hover {
    ${HeaderItemHover};
  }

  svg {
    align-self: center;
    max-width: 100%;
  }
`

export const SubHeading = styled.div`
  font-size: 16px;
  color: #fff;
  line-height: 1.4;
  font-weight: 600;
  margin-bottom: 16px;
  // display: none;
`

export const Container = styled.div`
  ${screen.sm_min} {
    padding-top: 40px;
  }

  ${screen.sm} {
    padding-top: 115px;
  }

  ${({ template }) =>
    template === 'faq' &&
    css`
      padding-top: 40px;
    `};
`

export const Content = styled.div``
