import React, { useState } from 'react'
import Section from 'components/atoms/Section'
import * as S from './styles'
import InfoModal from 'components/molecules/InfoModal'
import * as selectors from 'store/page/selectors'
import { connect } from 'react-redux'
import ColumnsList from 'components/atoms/ColumnsList'
import Button from 'components/atoms/Button'
import { getOptimizeImage } from 'utils/helpers'

const ServicesList = ({ serviceBlocks, type, page }) => {
  const [modalInfo, setModalInfo] = useState(null)
  return serviceBlocks.map(({ overhead, title, service_blocks }) => (
    <S.BackgroundWrapper key={title}>
      <Section backgroundText={type || true}>
        <S.HeadingWrapper
          children={type === undefined ? page.data.attributes.overhead : 'Услуги'}
          subTitle={type === undefined ? page.data.attributes.name : ''}
          subTitleColor="#D1D1D1"
        />

        <S.ListWrapper>
          <ColumnsList columns={2} type={type}>
            {service_blocks.data.map(
              ({
                attributes: { overhead, title, description, image, clubs, button, popup_link_text },
              }) => (
                <S.Item
                  type={type}
                  key={title}
                  onClick={() =>
                    setModalInfo({
                      title: title,
                      subtitle: overhead,
                      description: description,
                      clubsList: clubs.data,
                      button: button.data,
                    })
                  }
                >
                  <S.BackgroundImage>
                    <img
                      src={getOptimizeImage(image.data.attributes.url, {
                        width: '508',
                        height: '508',
                      })}
                      alt={image.data.attributes.alt}
                    />
                  </S.BackgroundImage>
                  <S.Name subTitle={overhead} subTitleColor="#D1D1D1" size={2}>
                    {title}
                  </S.Name>
                  <Button type="border" as="button" view={type}>
                    {popup_link_text}
                  </Button>
                </S.Item>
              ),
            )}
          </ColumnsList>
        </S.ListWrapper>
      </Section>
      <S.Background type={type} />
      <InfoModal info={modalInfo} setModalInfo={setModalInfo} />
    </S.BackgroundWrapper>
  ))
}

const mapStateToProps = state => ({
  page: selectors.selectContent(state),
  serviceBlocks: selectors.selectServiceBlocks(state),
})

export default connect(mapStateToProps)(ServicesList)
