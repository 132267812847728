import React from 'react'
import * as S from './styles'
import * as selectors from 'store/page/selectors'
import { connect } from 'react-redux'
import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'
import { ReactComponent as ArrowIcon } from 'assets/svg/gallery-arrow.svg'
import { screenSize } from 'config/theme'
import { getOptimizeImage } from 'utils/helpers'

const sliderParams = {
  navigation: {
    prevEl: '.arrow-prev',
    nextEl: '.arrow-next',
  },
  pagination: {
    el: '.pagination',
    type: 'bullets',
    clickable: true,
  },
  breakpoints: {
    [screenSize.sm]: {
      slidesPerView: 2,
    },
  },
  speed: 1000,
  shouldSwiperUpdate: true,
  preloadImages: false,
  lazy: true,
  renderPrevButton: () => (
    <S.Arrow className="arrow-prev">
      <ArrowIcon />
    </S.Arrow>
  ),
  renderNextButton: () => (
    <S.Arrow className="arrow-next">
      <ArrowIcon />
    </S.Arrow>
  ),
}

const ClubsSlider = ({ gallery }) =>
  gallery.map(({ uploaded_files }, index) => (
    <S.Wrapper key={index}>
      <S.SliderWrapper>
        <Swiper {...sliderParams}>
          {uploaded_files.data.map(url => (
            <S.Slide key={url.attributes.url}>
              <S.Content
                background={getOptimizeImage(url.attributes.url, { width: '580', height: '745' })}
              />
            </S.Slide>
          ))}
        </Swiper>
      </S.SliderWrapper>
    </S.Wrapper>
  ))

const mapStateToProps = state => ({
  gallery: selectors.selectGallery(state),
})

export default connect(mapStateToProps)(ClubsSlider)
