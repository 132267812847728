import styled, { css } from 'styled-components/macro'
import Heading from 'components/atoms/Heading'
import { screen } from 'config/theme'

export const Wrapper = styled.div`
  margin: 0 0 200px;
  position: relative;
  z-index: 1;
  display: flex;

  ${screen.md_max} {
    display: block;
  }

  ${({ template }) =>
    template !== 'page' &&
    css`
      margin-top: 100px;
    `}

  ${screen.xs} {
    ${({ template }) =>
      template !== 'page' &&
      css`
        margin-top: 0;
        margin-bottom: 90px;
      `}
  }

  ._half & {
    max-width: 510px;
    flex-direction: column;
  }
`

export const SubHeading = styled.div`
  font-size: 14px;
  color: #888;
  line-height: 16px;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  font-weight: 600;
`

export const Caption = styled(Heading)`
  display: block;
  margin-bottom: 40px;
  line-height: 1.2;
`

export const Info = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  max-width: 100%;

  ${({ template }) =>
    template !== 'page' &&
    css`
      max-width: 68%;
      flex: 0 0 68%;
      padding-left: 70px;
      margin-top: 20px;

      ${screen.md_max} {
        padding-left: 0;
        padding-top: 25px;
        max-width: 100%;
        margin-top: 0px;
      }
    `}
`

export const Item = styled.div`
  border-bottom: 1px solid #414141;

  ${({ isOpen }) =>
    isOpen &&
    css`
      ${Cross} {
        transform: rotate(45deg);

        :before,
        :after {
          background: #fff;
        }
      }
    `}
`

export const Title = styled.div`
  transition: all 0.3s ease;
  margin-right: 15px;
`

export const Line = styled.div`
  font-size: 28px;
  line-height: 1.2;
  font-weight: 700;
  padding: 20px 0;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  user-select: none;

  :hover {
    ${Title} {
      color: #fa2a47;
    }
  }

  ${screen.md} {
    font-size: 24px;
  }

  ${screen.xs} {
    font-size: 20px;
  }
`

export const Cross = styled.div`
  width: 70px;
  height: 33px;
  position: relative;
  margin-left: auto;
  transition: all 0.3s ease;
  flex-shrink: 0;

  :before,
  :after {
    content: ' ';
    width: 19px;
    height: 2px;
    position: absolute;
    background: #fa2a47;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease;
  }

  :after {
    transform: translate(-50%, -50%) rotate(90deg);
  }

  ${screen.xs} {
    width: 19px;
    height: 24px;
  }
`

export const Content = styled.div`
  padding: 0 0 20px;
  color: #888;
  width: 85%;

  ul {
    list-style: disc;
    list-style-position: outside;
  }
`
