import React from 'react'
import * as S from './styles'
import Text from 'components/atoms/Text'
import Button from 'components/atoms/Button'
import * as selectors from 'store/page/selectors'
import { connect } from 'react-redux'

const ErrorInfo = ({ errorInfo }) =>
  errorInfo.map(({ title, description, image, buttons }) => (
    <S.SectionItem backgroundImage={`${process.env.REACT_APP_HOST}${image}`} key={description}>
      <S.HeadingWrapper level="2" margin="0 0 60px 0">
        {title}
      </S.HeadingWrapper>
      <Text maxWidth="330px" margin="0 0 20px 0">
        {description}
      </Text>
      {buttons.data.map(({ attributes: { text, url } }) => (
        <Button type="border" to={url} key={text}>
          {text}
        </Button>
      ))}
    </S.SectionItem>
  ))

const mapStateToProps = (state, { source }) => ({
  errorInfo: selectors.selectError(source)(state),
})

export default connect(mapStateToProps)(ErrorInfo)
