import React, { useState, useLayoutEffect } from 'react'
import * as selectors from 'store/page/selectors'
import * as S from './styles'
import * as trainingsSelectors from 'store/trainings/selectors'
import * as actions from 'store/trainings/actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getDate, isToday, lastDate } from 'utils/helpers'
import { selectList, sliderParams, timeAliases } from './scheduleParams'

import 'swiper/css/swiper.css'
import Swiper from 'react-id-swiper'
import InfoModal from '../InfoModal'
import Questions from 'components/molecules/Questions'
import Section from 'components/atoms/Section'

const getFilteredTrainings = (data, filters) => {
  const { filteredClub, time } = filters

  return data.filter(({ club, name, workingHours }) => {
    const isMatchClub = filteredClub
      ? club.id === parseInt(filteredClub.substr(filteredClub.length - 1))
      : true
    const [hours] = workingHours.start.split(':')
    const isMatchTime = time ? timeAliases[time](+hours) : true
    return isMatchClub && isMatchTime
  })
}

const ScheduleList = ({ trainings, fetchTrainings, trainingsLoading, faqs, page }) => {
  const [filters, setFilters] = useState({ filteredClub: null, time: null })
  const [modalInfo, setModalInfo] = useState(null)

  useLayoutEffect(() => {
    fetchTrainings()
  }, [fetchTrainings])

  return (
    <S.Overflow>
      <S.SectionWrapper>
        <S.HeadingWrapper
          level={2}
          margin="0 0 60px"
          subTitle={page.data.attributes.overhead}
          children={page.data.attributes.name}
        />

        <S.Filters>
          {selectList.map(({ name, allOption, content, index }) => (
            <S.SelectItem
              key={name}
              allOption={allOption}
              value={filters[name]}
              options={content}
              onChange={value => setFilters({ ...filters, [name]: value })}
            />
          ))}
        </S.Filters>
        {Object.entries(trainings).length === 0 && !trainingsLoading && (
          <S.HeadingWrapper level={2} margin="40px 0">
            Извините, выбранных вами тренировок нет
          </S.HeadingWrapper>
        )}
        <S.SliderWrapper>
          <S.Wrapper isLoaded={Object.entries(trainings).length !== 0}>
            {Object.entries(trainings).length !== 0 && (
              <Swiper {...sliderParams}>
                {Object.entries(trainings).map(([date, list]) => (
                  <S.Column key={date}>
                    <S.Content>
                      <S.Caption>
                        <S.Day>{getDate(list[0].startDate, 'day')}</S.Day>
                        <S.DayName>
                          <span>{isToday(list[0].startDate) && 'Сегодня – '}</span>
                          <span>{getDate(list[0].startDate, 'weekday')}</span>
                        </S.DayName>
                      </S.Caption>
                      {getFilteredTrainings(list, filters).map(
                        ({
                          id,
                          club,
                          startDate,
                          finishDate,
                          name,
                          serviceDescription,
                          trainer,
                          serviceName,
                        }) => (
                          <S.Item
                            key={id + name}
                            isToday={isToday(startDate)}
                            isLastDate={lastDate(startDate)}
                            onClick={() => {
                              setModalInfo({
                                title: name,
                                subtitle: club.name,
                                description: serviceDescription,
                                columns: [
                                  {
                                    title: 'Дата',
                                    value: getDate(startDate, 'day'),
                                  },
                                  {
                                    title: 'Время:',
                                    value: `${getDate(startDate, 'time')} — ${getDate(
                                      finishDate,
                                      'time',
                                    )}`,
                                  },
                                  {
                                    title: 'Место:',
                                    value: club.name,
                                  },
                                  {
                                    title: 'Тренер:',
                                    value: trainer,
                                  },
                                ],
                              })
                            }}
                          >
                            <S.Club>{club.name}</S.Club>
                            <S.Time>
                              {getDate(startDate, 'time')} — {getDate(finishDate, 'time')}
                            </S.Time>
                            <S.Name>{name}</S.Name>
                            <S.Class>{trainer}</S.Class>
                          </S.Item>
                        ),
                      )}
                    </S.Content>
                  </S.Column>
                ))}
              </Swiper>
            )}
          </S.Wrapper>

          <InfoModal info={modalInfo} setModalInfo={setModalInfo} />
        </S.SliderWrapper>
        <Section>
          <Questions list={faqs} />
        </Section>
      </S.SectionWrapper>
    </S.Overflow>
  )
}

const mapStateToProps = state => ({
  page: selectors.selectContent(state),
  trainings: trainingsSelectors.selectTrainingsByDate(state),
  trainingsLoading: trainingsSelectors.selectTrainingsLoadingState(state),
  faqs: selectors.selectFaqs(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchTrainings: actions.fetchTrainingsRequest,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleList)
