import styled, { css } from 'styled-components/macro'
import { margin } from 'styled-system'
import { screen } from 'config/theme'

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;

  ${screen.md_min} {
    padding-top: 90px;
  }

  ${screen.md} {
    padding-top: 80px;
  }

  ${screen.sm} {
    padding-top: 70px;
  }

  ${screen.xs} {
    padding-top: 40px;
  }

  ${({ background }) =>
    background &&
    css`
      background: ${background};
      background-color: #000;
    `}

  ${({ backgroundImage }) =>
    backgroundImage &&
    css`
      background-image: url(${backgroundImage});
      background-size: cover;
      background-position: center;
    `}

  ${({ backgroundSize }) =>
    backgroundSize &&
    css`
      background-size: ${backgroundSize};
    `}

  ${({ fullHeight }) =>
    fullHeight &&
    css`
      min-height: 100vh;
      display: flex;
      align-items: center;

      ${Content} {
        width: 100%;
        display: flex;
        flex-direction: column;

        @media (min-width: 1024px) {
          min-height: calc(100vh - 100px);
        }
      }
    `}

  ${({ overlay }) =>
    overlay &&
    css`
      :after {
        content: ' ';
        background: ${overlay};
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
      }
    `}
`

export const Content = styled.div`
  width: 100%;
  margin: 0 auto;
  ${margin};
  position: relative;
  z-index: 1;

  ${({ fullWidth }) =>
    !fullWidth &&
    css`
      max-width: 1308px;
    `};

  ${({ fullWidth }) =>
    fullWidth === 'middle' &&
    css`
      max-width: 1014px;
    `};

  ${screen.xs_min} {
    padding: 0 70px;
  }

  ${screen.xs} {
    padding: 0 30px;
  }
`

export const Video = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: 100%;
`

export const BackgroundText = styled.div`
  will-change: transform;
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  transform: translate(-50%, 20%);
  max-width: 1370px;
  z-index: -1;

  ${({ offsetY }) =>
    css`
      transform: translate(-50%, ${offsetY}%);
    `};

  svg {
    width: 100%;
    height: auto;
  }
`

export const BackgroundGradient = styled.div`
  position: absolute;
  top: 40px;
  left: 50%;
  width: 96%;
  transform: translateX(-50%);
  max-width: 1370px;

  svg {
    width: 100%;
    height: auto;
  }
`
