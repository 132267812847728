import React from 'react'
import * as S from './styles'

const Preloader = ({ isLoading }) => {
  return (
    <S.Wrapper>
      <S.Image />
    </S.Wrapper>
  )
}

export default Preloader
