import React from 'react'
import Section from 'components/atoms/Section'
import Heading from 'components/atoms/Heading'
import * as S from './styles'
import ColumnsList from 'components/atoms/ColumnsList'
import * as selectors from 'store/page/selectors'
import { connect } from 'react-redux'
import { isMobile } from 'react-device-detect'
import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'
import { getOptimizeImage } from 'utils/helpers'

const Advantages = ({ benefits }) => {
  const sliderParams = {
    navigation: {
      prevEl: '.arrow-prev',
      nextEl: '.arrow-next',
    },
    breakpoints: {
      768: {
        spaceBetween: 55,
        autoHeight: true,
        slidesPerView: 2,
      },
      320: {
        spaceBetween: 40,
        autoHeight: true,
      },
    },
    loop: false,
    slidesPerView: 'auto',
    speed: 1000,
    shouldSwiperUpdate: true,
    preloadImages: false,
    lazy: true,
    loadPrevNext: true,
    renderPrevButton: () => <S.Arrow className="arrow-prev" />,
    renderNextButton: () => <S.Arrow className="arrow-next" />,
  }

  return benefits.map(({ overhead, title, benefit_blocks }) => (
    <Section key={title} margin="60px auto 0" backgroundText>
      <Heading subTitle={overhead}>{title}</Heading>
      {isMobile ? (
        <S.SliderWrapper>
          <Swiper {...sliderParams}>
            {benefit_blocks.data.map(({ attributes: { overhead, title, description, image } }) => (
              <S.Slide key={title}>
                <S.ItemMobileImage>
                  <img
                    src={getOptimizeImage(image.data.attributes.url, {
                      width: '400',
                      height: '260',
                    })}
                    alt={image.data.attributes.alt}
                  />
                </S.ItemMobileImage>
                <S.ItemMobileContent>
                  <S.HeadingWrapper
                    isMobile={isMobile}
                    subTitle={overhead}
                    size={2}
                    subTitleColor="#D1D1D1"
                  >
                    {title}
                  </S.HeadingWrapper>
                  <S.ItemMobileText dangerouslySetInnerHTML={{ __html: description }} />
                </S.ItemMobileContent>
              </S.Slide>
            ))}
          </Swiper>
        </S.SliderWrapper>
      ) : (
        <ColumnsList>
          {benefit_blocks.data.map(({ attributes: { overhead, title, description, image } }) => (
            <S.Item key={title}>
              <S.ItemContent>
                <S.HeadingWrapper
                  isMobile={isMobile}
                  subTitle={overhead}
                  size={2}
                  subTitleColor="#D1D1D1"
                >
                  {title}
                </S.HeadingWrapper>
                <S.Text dangerouslySetInnerHTML={{ __html: description }} />
              </S.ItemContent>
              <S.ItemBackground>
                <img
                  src={getOptimizeImage(image.data.attributes.url, { width: '300', height: '700' })}
                  alt={image.data.attributes.alt}
                />
              </S.ItemBackground>
            </S.Item>
          ))}
        </ColumnsList>
      )}
    </Section>
  ))
}

const mapStateToProps = state => ({
  benefits: selectors.selectBenefits(state),
})

export default connect(mapStateToProps)(Advantages)
