import styled, { css } from 'styled-components/macro'
import Heading from 'components/atoms/Heading'
import { screen } from 'config/theme'

export const HeadingWrapper = styled(Heading)`
  span {
    color: #d1d1d1;
    transition: color 0.4s ease-out;
    margin-bottom: 6px;
  }

  ${({ isMobile }) =>
    isMobile
      ? css`
          margin-bottom: 12px;
        `
      : css`
          transform: translateY(-50%);
          transition: transform 0.4s ease-out;
        `}
`

export const Text = styled.div`
  padding-top: 30px;
  transition: all 0.4s ease-out;
  opacity: 0;
`

export const ItemContent = styled.div`
  text-align: center;
  padding: 85px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease-out;
  pointer-events: none;
  position: relative;
  z-index: 2;
  transform: translateY(calc(50% - 85px));
`

export const ItemBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: grayscale(0%);
    transition: all 0.4s ease-out;
  }
`

export const Item = styled.div`
  position: relative;
  z-index: 1;
  will-change: transform;
  cursor: initial;
  overflow: hidden;

  &:before,
  &:after {
    content: ' ';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.4s ease-out;
  }

  &:before {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.14) 81.68%);
    opacity: 1;
  }

  &:after {
    background: rgba(40, 40, 40, 0.83);
    opacity: 0;
  }

  &:hover {
    ${HeadingWrapper} {
      transform: translateY(0);
    }

    ${ItemBackground} {
      img {
        filter: grayscale(100%);
      }
    }

    ${ItemContent} {
      transform: translateY(0%);
    }

    ${Text} {
      opacity: 1;
      transform: translateY(0%);
    }

    &:before {
      opacity: 0;
    }

    &:after {
      opacity: 1;
    }
  }
`

export const ItemMobileImage = styled.div`
  height: 220px;
  margin-bottom: 30px;

  ${screen.xs} {
    height: 160px;
  }

  img {
    max-width: initial;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
`
export const Slide = styled.div``

export const ItemMobileContent = styled.div``

export const ItemMobileText = styled.div`
  font-size: 14px;
  color: #888;
`
export const SliderWrapper = styled.div`
  padding: 90px 0 0 0;

  ${screen.xs} {
    padding: 70px 0 30px 0;
  }

  .swiper-container {
    max-width: 94%;
    margin-left: 0;
    overflow: visible;
  }
`

export const Arrow = styled.div`
  width: 50px;
  height: 50px;
  position: absolute;
  top: -80px;
  border-radius: 50%;
  user-select: none;
  z-index: 1;
  color: #fa2a47;
  border: 2px solid #fa2a47;
  transition: all 0.3s ease;
  cursor: pointer;

  ${screen.xs} {
    top: -50px;
    width: 35px;
    height: 35px;
  }

  &.swiper-button-disabled {
    border-color: #fff;

    &:before {
      border-left-color: #fff;
      border-bottom-color: #fff;
    }
  }

  &:before {
    content: ' ';
    height: 16px;
    width: 16px;
    border-left: 2px solid #fa2a47;
    border-bottom: 2px solid #fa2a47;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-35%, -50%) rotate(45deg);
    transition: all 0.3s ease;
    opacity: 1 !important;

    ${screen.xs} {
      height: 11px;
      width: 11px;
    }
  }

  &.swiper-button-disabled {
    pointer-events: none;
    color: #d1d1d1;
  }

  &.arrow-prev {
    left: auto;
    transform: none;

    ${screen.xs_min} {
      right: calc(-6% + 72px);
    }

    ${screen.xs} {
      right: calc(-6% + 50px);
    }
  }

  &.arrow-next {
    right: -6%;
    transform: rotate(180deg);
  }
`
