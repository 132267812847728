import React from 'react'
import DefaultTemplate from 'components/templates/Default'
import ServicesList from 'components/molecules/ServicesList'
import Section from 'components/atoms/Section'
import Questions from 'components/molecules/Questions'

import { connect } from 'react-redux'
import * as selectors from 'store/page/selectors'

const Service = ({ faqs, popUps }) => (
  <DefaultTemplate slug="services">
    <ServicesList />
    <Section>
      <Questions list={faqs} />
    </Section>
  </DefaultTemplate>
)

const mapStateToProps = state => ({
  popUps: selectors.selectPopUp(state),
  faqs: selectors.selectFaqs(state),
})

export default connect(mapStateToProps)(Service)
