import React, { useState, useEffect } from 'react'
import * as S from './styles'
import HeaderMenu from './menu'
import * as selectors from 'store/page/selectors'
import { connect } from 'react-redux'
import { ReactComponent as LoginIcon } from 'assets/svg/login.svg'
import ClubsTabs from 'components/molecules/ClubsTabs'
import { overflowBody } from 'utils/helpers'

const Header = ({ header, clubs }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const filteredClubs = clubs.filter(item => item.link_url === window.location.pathname)

  useEffect(() => {
    const headerEl = document.querySelector('.section--header')
    const headerHeight = document.querySelector('.section--header__inner').offsetHeight
    let treshold = 0
    let lastScroll = 0

    window.onscroll = () => {
      let newScroll = window.scrollY
      let diff = newScroll - lastScroll

      treshold = treshold + diff > headerHeight + 5 ? headerHeight + 5 : treshold + diff
      treshold = treshold < 0 ? 0 : treshold

      headerEl.style.top = `${-treshold}px`
      lastScroll = newScroll

      if (newScroll <= 0) {
        headerEl.style.top = `0px`
        lastScroll = newScroll
      }
    }

    menuOpen ? overflowBody(true) : overflowBody(false)
  })

  return (
    <React.Fragment>
      <S.Header className="section--header">
        <S.Wrapper className="section--header__inner">
          <S.LogoWrapper to="/">
            {header[0]?.image.data.attributes.url && (
              <img
                src={`${process.env.REACT_APP_HOST}${header[0].image.data.attributes.url}`}
                alt={`${process.env.REACT_APP_HOST}${header[0].image.data.attributes.alt}`}
              />
            )}
          </S.LogoWrapper>

          <S.Content>
            <S.NavLinkDesktop>
              <a>Клубы&nbsp;
              <svg width="17" height="9" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.5 8.5L0 0H17L8.5 8.5Z" fill="white"/>
              </svg></a>
              <S.NavSubmenu>
                <p><a href="/clubs-black">Kometa Black</a></p>
                
                <p><a href="/clubs-fit-columbus">Kometa Columbus</a></p>
                
                <p><a href="/clubs-fit-mari">Kometa Mari</a></p>
              </S.NavSubmenu>
            </S.NavLinkDesktop>

            <S.NavLinkDesktop>
              <a href="/subscription">Абонементы</a>
            </S.NavLinkDesktop>

            <S.NavLinkDesktop>
              <a href="/services">Услуги</a> 
            </S.NavLinkDesktop>

            <S.NavLinkDesktop>
              <a href="/schedule">Расписание</a> 
            </S.NavLinkDesktop>


            <S.Phone dangerouslySetInnerHTML={{ __html: header[0]?.description }} as="p" />

            {header[0]?.buttons?.data.map(({ attributes: { text, url } }, index) => (
              <S.ButtonWrapper
                type={header[0]?.buttons?.data.length !== index + 1 ? 'link' : 'outlineLink'}
                to={url}
                key={text}
              >
                {text}
              </S.ButtonWrapper>
            ))}

            <S.Burger onClick={() => setMenuOpen(!menuOpen)} menuOpen={menuOpen}>
              <span />
              <span />
              <span />
            </S.Burger>

            {header[0]?.buttons?.data.map(
              ({ attributes: { text, url } }, index) =>
                url.indexOf('lk.kometa.fit') > 1 && (
                  <S.LoginButton href={url} target="_blank" key={text}>
                    <LoginIcon />
                  </S.LoginButton>
                ),
            )}
          </S.Content>
        </S.Wrapper>
        {filteredClubs.length > 0 && !menuOpen && <ClubsTabs />}
      </S.Header>

      <HeaderMenu menuOpen={menuOpen} />
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  header: selectors.selectHeader(state),
  clubs: selectors.selectClubs(state),
})

export default connect(mapStateToProps)(Header)
