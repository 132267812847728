import React from 'react'
import * as S from './styles'
import * as selectors from 'store/page/selectors'
import { connect } from 'react-redux'

const FooterTopLine = ({ clubs, footer, contacts, id = '' }) => {
  const contactPage = window.location.pathname === '/contacts'
  const filteredClubs = clubs.filter(item => item.link_url === window.location.pathname)

  return (
    <S.SectionWrapper id={id}>
      <S.TopLine>
        <S.Addresses clubPage={filteredClubs.length}>
          {(filteredClubs.length ? filteredClubs : clubs).map(
            ({ name, logo, address, link_url }) => (
              <S.Column key={name} cols={filteredClubs.length || clubs.length}>
                <S.Title>
                  <a href={link_url} title={name} rel="noopener noreferrer">
                    <img
                      src={`${process.env.REACT_APP_HOST}${logo.data.attributes.url}`}
                      alt={logo.data.attributes.alt}
                    />
                  </a>
                </S.Title>
                <S.Text dangerouslySetInnerHTML={{ __html: address }} />
              </S.Column>
            ),
          )}

          {filteredClubs.length > 0 && (
            <S.Column clubPage={filteredClubs.length}>
              {footer.map(({ title, description }) => (
                <S.Info key={title}>
                  <div dangerouslySetInnerHTML={{ __html: title }} />
                  <div dangerouslySetInnerHTML={{ __html: description }} />
                </S.Info>
              ))}
            </S.Column>
          )}
        </S.Addresses>

        {!filteredClubs.length &&
          footer.map(({ title, description }) => (
            <S.Info key={title} contacts={contactPage}>
              <div dangerouslySetInnerHTML={{ __html: title }} />
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </S.Info>
          ))}

        {contactPage &&
          contacts.map(({ title, description }) => (
            <S.Info key={title} contacts={contactPage}>
              <div dangerouslySetInnerHTML={{ __html: title }} style={{ marginBottom: '50px' }} />
              <div
                dangerouslySetInnerHTML={{ __html: description }}
                style={{ marginBottom: '100px' }}
              />
            </S.Info>
          ))}
      </S.TopLine>
    </S.SectionWrapper>
  )
}

const mapStateToProps = state => ({
  clubs: selectors.selectClubs(state),
  footer: selectors.selectFooter(state),
  contacts: selectors.selectContacts(state),
})

export default connect(mapStateToProps)(FooterTopLine)
