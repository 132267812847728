import styled from 'styled-components/macro'
import { screen } from 'config/theme'
import Select from 'components/atoms/Select'

export const SelectItem = styled(Select)

export const Grid = styled.section`
  margin: 0 auto ${props => (props.template === 'main' ? '12px' : '70px')};

  ${screen.sm_min} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`

export const SubscriptionGeneral = styled.div`
  border: 1px solid #fff;
  padding: 140px 106px 140px 75px;
  max-width: calc(100% / 3 * 2 - 19px);
  margin-bottom: 57px;
  flex: 1;

  ${screen.md} {
    padding: 70px 40px;
  }

  ${screen.lg_min} {
    padding: 100px 97px;
  }

  ul {
    font-size: 20px;
    line-height: 1.1;
    padding-left: 22px;
    margin-top: 30px;
  }

  li {
    list-style-type: disc;
    margin-bottom: 16px;
  }
`
