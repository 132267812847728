import styled, { css } from 'styled-components/macro'
import { screen } from 'config/theme'
import Section from 'components/atoms/Section'
import Heading from 'components/atoms/Heading'
import 'animate.css'

export const Slider = styled.div`
  /* stylelint-disable */
  animation: fadeInRight 0.8s ease both 0.2s;
  /* stylelint-enable */

  .swiper-container {
    overflow: initial;
  }
`

export const Slide = styled.div`
  height: 37vh !important;

  ${screen.sm} {
    max-width: calc(100% - 60px);
    height: 43vw !important;
    margin-right: 60px;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      transition: all 0.8s ease !important;
    `}

  &.swiper-slide-next,
  &.swiper-slide-prev {
    padding: 0 8%;

    ${screen.sm} {
      padding: 0;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const BackgroundWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 50px 70px;

  ${screen.sm} {
    padding: 0;
  }
`

export const Background = styled.div`
  position: sticky;
  width: 100%;
  height: calc(100vh - 170px);
  background: ${({ background }) => `url(${background})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 0;
  top: 120px;
  left: 0;
  overflow: hidden;

  &:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(61.2deg, rgba(0, 0, 0, 0.8) 10.42%, rgba(0, 0, 0, 0) 100%);
  }
`

export const BackgroundContent = styled.div`
  background-image: ${({ background }) => `url(${background})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.3s linear;
  will-change: opacity;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* stylelint-disable */
    background-image: linear-gradient(
      60deg,
      rgba(0, 0, 0, 0.95) 0%,
      rgba(0, 0, 0, 0.75) 40%,
      rgba(0, 0, 0, 0) 100%
    );
    /* stylelint-enable */
  }

  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
    `}
`

export const Wrapper = styled.div`
  position: relative;
`

export const HeadingWrapper = styled(Heading)`
  max-width: 450px;
  margin-bottom: 40px;
`

export const Text = styled.div`
  max-width: 450px;
`

export const Preview = styled.div`
  ${screen.xs_min} {
    padding: 20% 0 13%;
  }

  ${screen.lg_md} {
    padding-left: 6%;
  }

  ${screen.md_sm} {
    padding-left: 7%;
  }

  ${screen.xs} {
    padding: 24% 0 20%;
  }
`

export const List = styled.div`
  ${screen.lg_md} {
    padding-left: 6%;
  }

  ${screen.md_sm} {
    padding-left: 7%;
  }
`

export const LogoImage = styled.img``

export const LogoImageAlt = styled.img`
  position: absolute;
  top: 100%;
  left: 0;
  height: 100%;
  width: auto;
`

export const Logo = styled.div`
  position: relative;
  cursor: pointer;
  display: inline-block;

  &:not(:hover) {
    color: #fff;
  }

  svg {
    transition: 0.15s ease-out;
  }

  svg:not([fill]) {
    fill: currentcolor;
    height: 100px;
    width: auto;

    ${screen.lg_max} {
      height: 7vmin;
    }
  }

  &:hover {
    ${LogoImage} {
      opacity: 0.7;
    }
  }
`

export const Item = styled.div`
  margin-bottom: 40px;
  position: relative;

  ${screen.sm} {
    margin-bottom: 25px;
  }

  .react-slidedown {
    transition-duration: 1s;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      ${Logo} img {
        transform: translateY(-100%);
      }
    `}
`

export const Title = styled.div`
  font-weight: 700;
  font-size: 28px;
  line-height: 120%;
  max-width: 450px;
  margin-bottom: 20px;
`

export const Description = styled.div`
  max-width: 650px;
  margin-bottom: 50px;

  ${screen.md_max} {
    max-width: 700px;
  }
`

export const Arrow = styled.div`
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  margin-top: -25px;
  border-radius: 50%;
  user-select: none;
  z-index: 1;
  border: 2px solid transparent;
  background: rgba(255, 255, 255, 0.45);
  transition: all 0.3s ease;
  cursor: pointer;

  &:before {
    content: ' ';
    height: 16px;
    width: 16px;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-35%, -50%) rotate(45deg);
    transition: all 0.3s ease;
    opacity: 1 !important;
  }

  &:hover {
    border-color: #fff;
    background-color: transparent !important;

    &:before {
      border-color: #fff;
    }
  }

  &.swiper-button-disabled {
    opacity: 0;
    pointer-events: none;
  }

  &.arrow-prev {
    left: -25px;
    transform: none;

    ${screen.md_max} {
      transform: scale(0.7);
    }
  }

  &.arrow-next {
    right: -25px;
    transform: rotate(180deg);

    ${screen.md_max} {
      transform: rotate(180deg) scale(0.7);
    }
  }
`

export const Close = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #fff;
  position: absolute;
  transition: border 0.3s ease;
  z-index: 10;
  cursor: pointer;
  opacity: 1;
  top: 8vh;
  right: 12vh;

  ${screen.md} {
    top: 22px;
    right: 5px;
    transform: scale(0.7);
  }

  &:after,
  &:before {
    content: ' ';
    width: 22px;
    height: 2px;
    background: #fff;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: 50% 50%;
    transition: transform 0.3s ease, background 0.3s ease;
  }

  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:hover {
    border-color: #fa2a47;

    &:after,
    &:before {
      background: #fa2a47;
    }
  }
`
export const SectionWrapper = styled(Section)`
  padding-bottom: 10%;
`

export const SectionPopup = styled(Section)`
  padding: 0;
`

export const Content = styled.div`
  padding: 12vh 0 4vh;
`

export const OverflowPopup = styled.div`
  overflow: auto;
  height: 100%;
`
export const ContentInner = styled.div`
  /* stylelint-disable */
  animation: fadeInRight 0.8s ease both 0.2s;
  /* stylelint-enable */
`

export const Popup = styled.div`
  transform: translateX(100%);
  background: #000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  will-change: transform;
  /* stylelint-disable */
  animation: slideInRight 0.7s ease forwards;
  /* stylelint-enable */

  ${({ isClose }) =>
    isClose &&
    css`
      /* stylelint-disable */
      animation: slideOutRight 0.5s ease both 0.1s;

      ${ContentInner},
      ${Slider} {
        animation: fadeOutRight 0.5s ease both;
      }

      /* stylelint-enable */
    `}

  ${Logo} {
    margin-bottom: 50px;
  }

  ${BackgroundContent} {
    margin: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    ${screen.sm} {
      margin: 0;
      width: 100%;
      height: 100%;
    }
  }
`
