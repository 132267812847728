import React, { useState } from 'react'
import { pluralize } from 'utils/helpers'
import SubscriptionItemSimple from './SubscriptionItemSimple'
import SubscriptionItemLast from './SubscriptionItemLast'
import SubscriptionItemGeneral from './SubscriptionItemGeneral'

const SubscriptionItem = ({ item, template, general, isLastElement, cols }) => {
  // Variable for subscription data
  const subscriptionSubBlocks = item.sub_blocks.data.length
    ? item.sub_blocks.data[0].attributes
    : []

  // state toggler for selects
  const [filter, setFilter] = useState(
    item.sub_blocks.data.length > 1 ? subscriptionSubBlocks.number_of_months : false,
  )

  // BUILD options for monthes
  let monthOptions = []
  item.sub_blocks.data.map((item, index) => {
    monthOptions.push({})
    monthOptions[index].key = item.attributes.number_of_months
    monthOptions[index].price = item.attributes.price
    if (item.attributes.title !== null && item.attributes.title !== '') {
      monthOptions[index].label = item.attributes.title
    } else {
      monthOptions[index].label = `${item.attributes.number_of_months} ${pluralize(
        item.attributes.number_of_months,
        'месяц',
        'месяца',
        'месяцев',
      )}`
    }
  })

  // Filter current month
  const currentMonth = filter
    ? item.sub_blocks.data.filter(element => element.attributes.number_of_months === filter)
    : false

  const subscriptionProps = {
    subscriptionSubBlocks: subscriptionSubBlocks,
    subscription: item,
    monthOptions: monthOptions,
    currentMonth: currentMonth,
    filter: filter,
    setFilter: setFilter,
    isLastElement: isLastElement,
    template: template,
  }

  const getItem = type => {
    switch (type) {
      case 'last':
        return template === 'main' ? (
          <SubscriptionItemLast subscriptionProps={subscriptionProps} />
        ) : (
          <SubscriptionItemSimple subscriptionProps={subscriptionProps} />
        )
      case 'general':
        return <SubscriptionItemGeneral subscriptionProps={subscriptionProps} cols={cols} />
      default:
        return <SubscriptionItemSimple subscriptionProps={subscriptionProps} />
    }
  }

  return (
    <React.Fragment>
      {isLastElement && !general && getItem('last')}
      {!isLastElement && !general && getItem('simple')}
      {general && getItem('general')}
    </React.Fragment>
  )
}

export default SubscriptionItem
