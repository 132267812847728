import { LOCATION_CHANGE } from 'connected-react-router'

export const scrollResetMiddleware = () => next => action => {
  if (action.type === LOCATION_CHANGE) {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 800)
  }
  next(action)
}
