import styled, { css } from 'styled-components/macro'
import { screen } from 'config/theme'
import Section from 'components/atoms/Section'

export const SlideSection = styled(Section)`
  padding-bottom: 70px;
`

export const Slider = styled.div`
  display: flex;
  position: relative;
  border-bottom: 1px solid #363636;

  ${({ template }) =>
    template === 'secondary' &&
    css`
      ${screen.md_min} {
        margin-top: 66px;
      }
    `}
`

export const SlideContent = styled.div`
  width: 100%;
  max-width: 590px;
  will-change: transform;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  transition: all 0.8s ease 0s;
  z-index: 3;
  box-sizing: border-box;

  img {
    width: 100%;
    margin-bottom: 30px;
  }

  ${screen.md_min} {
    margin: auto 5vw;
    min-height: 400px;
  }
  ${screen.sm_max} {
    margin: auto 42px;
  }
`

export const SliderMain = styled.div`
  position: relative;
  height: 100%;
  width: 70%;

  ${screen.lg_max} {
    width: 100%;
  }

  .swiper-slide {
    height: auto;

    ${screen.md_min} {
      min-height: calc(100vh - 130px);


      ${({ template }) =>
        template === 'main' &&
        css`
          ${screen.xl_max} {
            height: calc(100vh - 130px);
            ${SlideContent} {
              // padding-top: 12vh;
            }
          }
        `}

      ${({ template }) =>
        template === 'secondary' &&
        css`
          min-height: calc(100vh - 200px);
          ${SlideContent} {
            // padding-top: 12vh;
          }
        `}

      ${({ template }) =>
        template === 'bottom' &&
        css`
          min-height: 500px;
          height: calc(100vh - 248px);
        `}
    }

    ${screen.md} {
      height: calc(100vh - 73px);
      min-height: 540px;

      ${({ template }) =>
        template === 'bottom' &&
        css`
          min-height: 640px;
          height: calc(100vh - 214px);
        `}
    }
  }

  .swiper-container {
    height: 100%;
  }
`

export const SliderThumb = styled.div`
  width: 30%;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  border-left: 1px solid #282828;

  ${({ template }) =>
    template === 'main' &&
    css`
      ${screen.xl_max} {
        height: calc(100vh - 130px);
        ${SlideContent} {
          // padding-top: 12vh;
        }
      }
    `}

  ${({ template }) =>
    template === 'secondary' &&
    css`
      min-height: calc(100vh - 200px);
      ${SlideContent} {
        // padding-top: 12vh;
      }
    `}

  ${screen.lg_max} {
    height: 130px;
    min-height: auto;
    top: initial;
    overflow: visible;

    .swiper-wrapper {
      opacity: 0;
      pointer-events: none;
    }
  }

  ${screen.md} {
    height: 70px;
    width: 140px;
    bottom: 0;
    right: 0;
  }

  .swiper-container {
    height: 100%;
  }

  .swiper-container-vertical > .swiper-pagination-bullets {
    background-color: #000;
    bottom: 0;
    height: 130px;
    text-align: right;
    padding: 0 13%;
    right: 0;
    top: auto;
    transform: none;
    width: 100%;
    z-index: 10;

    ${screen.md} {
      display: none;
    }
  }

  .swiper-pagination-bullet {
    background-color: #fff;
    opacity: 1;
    width: 7px;
    height: 7px;
    position: relative;

    ${screen.md} {
      display: none !important;
    }
  }

  .swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
    display: inline-block;
    margin: 62px 13px;
    ${screen.lg} {
      margin: 62px 10px;
    }
  }

  .swiper-pagination-bullet-active {
    background-color: #fa2a47;
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: #fa2a47;
    top: auto;
    bottom: 43px;

    ${screen.md} {
      width: 70px;
      height: 70px;
      background-color: #000;
      bottom: 0;
    }
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    ${screen.md} {
      font-size: 36px;
    }
  }

  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    color: #d1d1d1;
    opacity: 1;
  }

  .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 64px 11px;
  }

  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    left: 17%;

    ${screen.lg_min} {
      left: 75px;
    }

    ${screen.md} {
      right: auto;
      left: 0;
    }
  }

  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    right: auto;
    left: 35%;

    ${screen.lg_min} {
      left: 150px;
    }

    ${screen.md} {
      left: auto;
      right: 0;
    }
  }

  h2 {
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #fff;
    color: transparent;
  }
`

export const Slide = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;

  ${screen.md} {
    align-items: center;
  }
`

export const SlideActions = styled.div`
  margin-top: 40px;
  overflow: hidden;

  button,
  a {
    & + button,
    & + a {
      margin-top: 30px;
    }
  }
`

export const SliderArrow = styled.a`
  position: absolute;
  bottom: 35px;
  left: 20px;
  z-index: 5;
  animation: moveArrow 0.7s ease-in-out infinite alternate;

  ${screen.md} {
    bottom: 18px;
  }

  @keyframes moveArrow {
    to {
      transform: translateY(20%);
    }
  }
`
export const SlideMedia = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  will-change: transform;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  transition: all 0.8s ease 0s;

  video,
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    object-fit: cover;
    transform: scale(1.2);
    transition: transform 0.5s ease-out;

    .swiper-slide-active & {
      transform: scale(1);
    }
  }

  &:after {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(249.92deg, rgba(0, 0, 0, 0.05) -1.54%, #000 103.23%);
  }
`
