import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import * as S from './styles'

const PlainLink = ({ to, ...props }) => <S.Wrapper {...props} as="a" href={to} />
const RouteLink = ({ to, ...props }) => <S.Wrapper {...props} as="a" href={to} />
const ButtonLink = props => <S.Wrapper {...props} as="button" />
const ButtonOutline = props => <S.ButtonOutline {...props} as={RouterLink} />
const ButtonOutlineLink = ({ to, ...props }) => <S.ButtonOutline {...props} as="a" href={to} />
const BorderLink = ({ as, ...props }) => (
  <S.Borderbox {...props} as={as === 'button' ? 'button' : RouterLink} />
)

const types = {
  link: PlainLink,
  route: RouteLink,
  button: ButtonLink,
  border: BorderLink,
  outline: ButtonOutline,
  outlineLink: ButtonOutlineLink,
}

const Button = ({ type = 'button', children, ...props }) => {
  const ButtonComponent = types[type]
  return (
    <ButtonComponent {...props}>
      <S.Text dangerouslySetInnerHTML={{ __html: children }} />
      {/*{type === 'border' && <S.TextOverlay dangerouslySetInnerHTML={{ __html: children }} />}*/}
    </ButtonComponent>
  )
}

export default Button
