import styled from 'styled-components/macro'
import Heading from 'components/atoms/Heading'
import { screen } from 'config/theme'

export const Container = styled.div`
  margin: auto auto 15vh;

  ${screen.md} {
    margin: 7vh 0 0;
  }

  ${screen.xs} {
  }
`

export const HeadingWrapper = styled(Heading)`
  span {
    color: #fa2a47;
  }
`
