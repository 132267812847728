import { parseActionType } from 'store/utils'

export default (state = {}, { type }) => {
  const matches = parseActionType(type)

  if (!matches) return state

  const [, actionType, actionState] = matches

  return {
    ...state,
    [actionType]: actionState === 'REQUEST',
  }
}
