import styled, { css } from 'styled-components/macro'
import { screen } from 'config/theme'
import Button from 'components/atoms/Button'
import Select from 'components/atoms/Select'

export const SelectItem = styled(Select)`
  display: block;
  min-width: auto;
`

export const SubscriptionItem = styled.div`
  background-color: #fff;
  color: #000;
  box-sizing: border-box;
  word-break: break-word;
  display: flex;
  flex-direction: column;
  width: 100%;

  ${screen.lg_min} {
    max-width: calc(100% / 3 - 38px);
    flex: 0 0 calc(100% / 3 - 38px);
    padding: 40px ${template => (template === 'main' ? 30 : 36)}px 60px;
    margin-bottom: 58px;
  }

  ${screen.lg_md} {
    max-width: calc(100% / 3 - 20px);
    flex: 0 0 calc(100% / 3 - 20px);
    padding: 40px 30px 60px;
    margin-bottom: 38px;
  }

  ${screen.md_sm} {
    max-width: calc(100% / 2 - 38px);
    flex: 0 0 calc(100% / 2 - 38px);
    padding: 40px 30px 60px;
    margin-bottom: 70px;
  }

  ${screen.sm} {
    margin-bottom: 50px;
    padding: 40px 38px 60px;
    max-width: 100%;
    flex: 0 0 100%;
  }

  ${({ isLastElement, template }) =>
    isLastElement &&
    template === 'main' &&
    css`
      ${screen.md_min} {
        max-width: 100%;
        flex: 0 0 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        min-height: initial;
      }
    `};

  hr {
    border: none;
    background: none;
    border-top: 1px solid #e0e0e0;
    margin: 15px ${template => (template === 'main' ? -30 : -36)}px 30px;

    ${screen.lg_md} {
      margin: 15px -30px 30px;
    }

    ${screen.md_sm} {
      margin: 15px -30px 30px;
    }
  }
`

export const SubscriptionItemColumn = styled.div`
  ${screen.md_min} {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h3 {
      font-size: 40px;
    }

    button {
      margin-top: 20px;
    }

    &:nth-child(1) {
      width: 60%;
    }

    &:nth-child(2) {
      width: 30%;
    }
  }
`
export const SubscriptionItemTitle = styled.h3`
  font-size: 24px;
  line-height: 1.25;
  font-weight: 900;
  margin-bottom: 30px;

  ${({ isLastElement }) =>
    isLastElement &&
    css`
      ${screen.md_min} {
        font-size: 40px;
      }
    `};
`

export const SubscriptionItemContent = styled.div`
  margin-bottom: 35px;

  ${screen.lg} {
    font-size: 14px;
  }

  p {
    margin-bottom: 15px;
  }

  ul {
    color: #888;
    list-style: disc;
    padding-left: 20px;
  }

  li {
    margin-bottom: 5px;
  }
`

export const SubscriptionItemPrice = styled.span`
  line-height: 1;
  font-weight: 900;
  letter-spacing: 0.5px;
  word-break: break-word;
  margin-bottom: 15px;
  font-size: 36px;

  ${screen.xs} {
    font-size: 30px;
  }

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `};

  i,
  em {
    font-size: 16px;
    line-height: 1;
    font-weight: 400;
    color: #888;
    display: block;
    margin: 4px 0;
    letter-spacing: 0.1px;
  }

  small {
    font-size: 62%;
  }

  & + a,
  & + button {
    margin-top: 55px;
  }
`
export const SubscriptionItemPriceСross = styled.span`
  i {
    line-height: 1;
    font-weight: 900;
    letter-spacing: 0.5px;
    display: inline-block;
    word-break: break-word;
    font-size: 24px;
    color: #a2a2a2;
    margin-bottom: 4px;
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: -2px;
      height: 1px;
      width: calc(100% + 4px);
      background-color: #a2a2a2;
    }
  }
`
export const SubscriptionGeneral = styled.div`
  border: 1px solid #fff;

  ${screen.lg_min} {
    margin-bottom: 58px;
    padding: 140px 100px;
  }

  ${screen.lg_md} {
    padding: 100px 85px;
    margin-bottom: 38px;
  }

  ${screen.md_sm} {
    padding: 40px 30px 60px;
    margin-bottom: 70px;
  }

  ${screen.sm} {
    padding: 40px 38px 60px;
    margin-bottom: 50px;
  }

  ${({ cols }) =>
    cols % 5 === 0 &&
    css`
      ${screen.lg_min} {
        padding: 40px 36px 60px;
        max-width: calc(100% / 3 - 38px);
        flex: 0 0 calc(100% / 3 - 38px);
      }
      ${screen.lg_md} {
        max-width: calc(100% / 3 - 38px);
        flex: 0 0 calc(100% / 3 - 38px);
        padding: 40px 30px 60px;
      }
      ${screen.md_sm} {
        max-width: calc(100% / 2 - 38px);
        flex: 0 0 calc(100% / 2 - 38px);
      }
    `};

  ${({ cols }) =>
    cols % 2 === 0 &&
    cols !== 4 &&
    css`
      ${screen.lg_min} {
        padding: 40px 36px 60px;
        max-width: calc(100% / 3 - 38px);
        flex: 0 0 calc(100% / 3 - 38px);
      }

      ${screen.lg_md} {
        max-width: calc(100% / 3 - 19px);
        flex: 0 0 calc(100% / 3 - 19px);
        padding: 40px 30px 60px;
      }

      ${screen.md_sm} {
        max-width: 100%;
        flex: 1 0 100%;

        h2 {
          font-size: 30px;
        }
      }
    `};

  ${({ cols }) =>
    cols % 3 === 0 &&
    css`
      ${screen.lg_min} {
        max-width: 100%;
        flex: 1 0 100%;
      }

      ${screen.lg_md} {
        max-width: 100%;
        flex: 1 0 100%;
      }

      ${screen.md_sm} {
        max-width: calc(100% / 2 - 38px);
        flex: 0 0 calc(100% / 2 - 38px);
      }
    `};

  ${({ cols }) =>
    cols % 4 === 0 &&
    css`
      ${screen.lg_min} {
        max-width: calc(100% / 3 * 2 - 19px);
        flex: 1 0 calc(100% / 3 * 2 - 19px);
      }

      ${screen.lg_md} {
        max-width: calc(100% / 3 * 2 - 10px);
        flex: 1 0 calc(100% / 3 * 2 - 10px);
      }

      ${screen.md_sm} {
        max-width: 100%;
        flex: 1 0 100%;
      }
    `};

  ul {
    font-size: 20px;
    line-height: 1.1;
    padding-left: 22px;
    margin-top: 30px;

    ${screen.lg} {
      font-size: 16px;
    }
  }

  li {
    list-style-type: disc;
    margin-bottom: 16px;
  }
`

export const SubscriptionItemButton = styled(Button)`
  min-width: auto;
  width: 100%;
  color: #fff;
  border: none;
  margin-top: auto;

  &:hover {
    border: 2px solid #fa2a47;
    color: #fa2a47;
  }
`
