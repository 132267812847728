import styled, { css } from 'styled-components/macro'
import { screen } from 'config/theme'

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #282828;
  background: #000;

  ${screen.sm} {
    display: none;
  }
`

export const HeaderItem = styled.a`
  margin: 0 30px;
  padding: 16px 0 15px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    border-bottom: 2px solid transparent;
    transition: 0.15s ease-out;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      &:after {
        border-color: #fff;
      }
    `}

  &:hover {
    &:after {
      border-color: #fff;
    }
  }

  img {
    max-height: 38px;
  }
`
