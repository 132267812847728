import styled from 'styled-components/macro'
import { screen } from 'config/theme'

export const Wrapper = styled.div`
  position: fixed;
  z-index: 6;
  background: #414141;
  right: 30px;
  bottom: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  transition: opacity 0.3s ease;
  cursor: pointer;

  ${screen.md_max} {
    display: none;
  }

  &:hover {
    opacity: 0.7;
  }

  svg {
    margin: 21px 17px 23px;
    align-self: center;
  }
`
