import React from 'react'
import { connect } from 'react-redux'
import * as selectors from 'store/page/selectors'
import * as S from './styles'

const ClubsTabs = ({ clubs }) => {
  return (
    <React.Fragment>
      <S.Header role="tablist" aria-label={`Табы для клубов`}>
        {clubs.map((item, index) => (
          <S.HeaderItem
            role="tab"
            href={item.link_url}
            aria-selected={index === 0}
            aria-controls={`panel-${index}`}
            isActive={item.link_url === window.location.pathname}
            key={`tabTitle-${index}`}
          >
            <img
              src={`${process.env.REACT_APP_HOST}${item.logo.data.attributes.url}`}
              alt={item.name}
              title={item.name}
              template={`${item.name === 'Black'}`}
            />
          </S.HeaderItem>
        ))}
      </S.Header>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  clubs: selectors.selectClubs(state),
})

export default connect(mapStateToProps)(ClubsTabs)
