import styled from 'styled-components/macro'
import Section from 'components/atoms/Section'
import { screen } from 'config/theme'

export const Wrapper = styled(Section)`
  padding-bottom: 16%;
  position: relative;
`

export const SliderWrapper = styled.div`
  .swiper-container {
    ${screen.sm_min} {
      padding-bottom: 57px;
    }

    ${screen.sm} {
      padding-bottom: 45%;
    }
  }

  .swiper-wrapper {
    pointer-events: none;
  }

  .swiper-slide {
    pointer-events: none;
  }

  .pagination {
    position: absolute;
    right: 0;
    display: inline-flex;
    bottom: 0 !important;
    left: auto !important;
    z-index: 10;

    ${screen.sm_min} {
      margin-right: -5px;
      width: initial !important;
    }

    ${screen.sm} {
      justify-content: center;
    }
  }

  .swiper-pagination-bullet {
    width: initial;
    height: initial;
    padding: 5px;
    background: transparent;
    opacity: 1;

    ${screen.sm_min} {
      margin: 0 0 0 13px !important;
    }

    ${screen.sm} {
      margin: 0 6px !important;
    }

    &:after {
      content: ' ';
      display: block;
      width: 7px;
      height: 7px;
      background: #fff;
      border-radius: 50%;
      transition: all 0.3s ease;
    }

    &:hover {
      &:after {
        background: #f9b2bc;
      }
    }
  }

  .swiper-pagination-bullet-active {
    pointer-events: none;

    &:after {
      background: #fa2a47;
    }
  }
`

export const Slide = styled.div`
  ${screen.sm_min} {
    transition: padding 1s ease !important;

    &.swiper-slide-active {
      padding-right: 12%;
    }

    &.swiper-slide-prev {
      padding-right: 10%;
    }

    &.swiper-slide-next + div {
      padding-left: 10%;
    }
  }
`

export const Content = styled.div`
  padding-bottom: 128%;
  background: ${({ background }) => `url(${background}) center`};
  background-size: cover;
`

export const Arrow = styled.div`
  display: inline-flex;
  position: absolute;
  z-index: 10;
  cursor: pointer;

  ${screen.sm_min} {
    width: 10%;
    bottom: 95px;
  }

  ${screen.sm} {
    width: 36%;
    bottom: 9%;
  }

  svg {
    width: 100%;
    height: auto;
    transition: all 0.3s ease;
  }

  &.arrow-prev {
    transform: rotate(180deg);
    left: 0;
  }

  &.arrow-next {
    ${screen.sm_min} {
      left: 28%;
    }

    ${screen.sm} {
      right: 0;
    }
  }

  &.swiper-button-disabled {
    pointer-events: none;

    svg {
      stroke: #414141;
    }
  }

  &:hover {
    svg {
      stroke: #fa2a47;
    }
  }
`
