import React, { Children } from 'react'
import * as S from './styles'
import { screenSize } from 'config/theme'

const ColumnsList = ({ children, columns = 3, type, props }) => {
  const breakpointColumns =
    columns === 3
      ? {
          default: 3,
          [screenSize.md - 1]: 2,
          [screenSize.sm - 1]: 1,
        }
      : {
          default: 2,
          [screenSize.sm - 1]: 1,
        }

  return (
    <S.Container
      breakpointCols={breakpointColumns}
      className="masonry"
      columnClassName="masonry__column"
      type={type}
    >
      {Children.map(children, child => (
        <S.Item type={type}>
          <S.ItemContent>
            {child}
            <S.ItemShadow type={type} />
          </S.ItemContent>
        </S.Item>
      ))}
    </S.Container>
  )
}

export default ColumnsList
