import React from 'react'
import * as selectors from 'store/page/selectors'
import { connect } from 'react-redux'

import DefaultTemplate from 'components/templates/Default'
import Heading from 'components/atoms/Heading'
import Section from 'components/atoms/Section'
import Text from 'components/atoms/Text'
import routes from 'config/routes'

const Contacts = ({ page }) => (
  <DefaultTemplate slug="contacts">
    <Section>
      <Heading
        level={2}
        margin="0 0 30px 0"
        subTitle={page.data.attributes.overhead}
        children={page.data.attributes.name}
      />
    </Section>
  </DefaultTemplate>
)

const mapStateToProps = state => ({
  page: selectors.selectContent(state),
})

export default connect(mapStateToProps)(Contacts)
