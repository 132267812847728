import styled from 'styled-components/macro'
import Section from 'components/atoms/Section'
import Heading from 'components/atoms/Heading'
import { screen } from 'config/theme'

export const SectionItem = styled(Section)`
  /* stylelint-disable */
  min-height: calc(100vh - 130px);
  display: flex;
  align-items: center;
  padding-bottom: 100px;

  ${screen.md} {
    min-height: calc(100vh - 72px);
  }
`

export const HeadingWrapper = styled(Heading)`
  max-width: 390px;
`

export const Content = styled.div`
  margin: auto 0;
  padding: 50px 0;
`
