import styled, { css } from 'styled-components/macro'

export const Line = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  height: inherit;
  cursor: pointer;
`

export const Arrow = styled.div`
  padding: 8px;
  transition: all 0.3s ease;
  display: flex;

  svg {
    fill: #fa2a47;
  }
`

export const Value = styled.div`
  flex-grow: 1;
  font-size: 20px;
  border-bottom: 1px solid #414141;
  transition: all 0.3s ease;
  height: inherit;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const Option = styled.div`
  font-size: 16px;
  color: #a2a2a2;
  transition: all 0.3s ease;
  margin-bottom: 8px;
  cursor: pointer;

  :last-child {
    margin-bottom: 0;
  }

  :hover {
    color: #fa2a47;
  }
`

export const Dropdown = styled.div`
  display: inline-block;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease;
  position: absolute;
  left: -35px;
  top: -25px;
  z-index: 1;
  background: #fff;
  padding: 70px 35px 35px;
  width: calc(100% + 70px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
`

export const Container = styled.div`
  display: inline-block;
  position: relative;
  min-width: 270px;
  user-select: none;
  margin-bottom: 30px;

  :hover {
    ${Value} {
      border-color: #fff;
    }
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      z-index: 3;
      ${Value} {
        border-color: transparent !important;
        color: #000;
      }
      ${Dropdown} {
        opacity: 1;
        pointer-events: initial;
      }
      ${Arrow} {
        transform: rotate(-180deg);
      }
    `};
`
