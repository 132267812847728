import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import * as selectors from 'store/page/selectors'

import * as S from './styles'
import styled from 'styled-components/macro'
import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'
import { screen } from 'config/theme'

import { ReactComponent as ArrowDown } from 'assets/svg/arrow_down.svg'
import Button from 'components/atoms/Button'
import Heading from 'components/atoms/Heading'
import Text from 'components/atoms/Text'

const HeroBanner = ({
  sliders,
  headingContent = {},
  content = [],
  type = '',
  template = '',
  id = '',
}) => {
  const [swiperMain, updateSwiperMain] = useState(null)
  const [swiperThumb, updateSwiperThumb] = useState(null)

  const paramsThumb = {
    direction: 'vertical',
    speed: 800,
    loop: false,
    watchSlidesProgress: true,

    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    spaceBetween: 0,
    preloadImages: false,
    lazy: true,
    loadPrevNext: true,
  }

  const paramsMain = {
    effect: 'slide',
    loop: false,

    watchSlidesProgress: true,
    speed: 800,
    spaceBetween: 0,
    preloadImages: false,
    lazy: true,
    loadPrevNext: true,
  }

  const goNext = () => {
    if (swiperMain.realIndex === swiperMain.slides.length - 1) {
      swiperMain.slideTo(0)
    } else if (swiperMain !== null) {
      swiperMain.slideNext()
    }
  }

  useEffect(() => {
    if (
      swiperMain !== null &&
      swiperMain.controller &&
      swiperThumb !== null &&
      swiperThumb.controller
    ) {
      swiperMain.controller.control = swiperThumb
      swiperThumb.controller.control = swiperMain
    }
  }, [swiperMain, swiperThumb])

  if (swiperMain !== null && swiperMain.controller) {
    ;[swiperMain, swiperThumb].map(item => {
      item.on('progress', () => {
        let that = item
        that.slides.each((index, slide) => {
          let slideProgress = slide.progress
          let innerOffset = item.params.direction === 'horizontal' ? that.width : that.height
          let innerTranslate = slideProgress * innerOffset
          that.slides[index].querySelector('.slide-inner').style.transform =
            item.params.direction === 'horizontal'
              ? `translate3d(${innerTranslate}px, 0, 0)`
              : `translate3d(0, ${innerTranslate}px, 0)`
          that.slides[index].querySelector('.slide-content').style.transform =
            item.params.direction === 'horizontal'
              ? `translate3d(${innerTranslate}px, 0, 0)`
              : `translate3d(0, ${innerTranslate}px, 0)`
        })
      })

      item.on('touchStart', () => {
        let that = item
        that.slides.each((i, item) => {
          that.slides[i].style.transition = ''
        })
      })

      item.on('setTransition', speed => {
        let that = item
        that.slides.each((i, item) => {
          let slide = that.slides[i]
          slide.style.transition = `${speed}ms`
          slide.querySelector('.slide-inner').style.transition = `${speed}ms`
          slide.querySelector('.slide-content').style.transition = `${speed}ms`
        })
      })
    })
  }

  let slidersFiltered = sliders.filter(item => item.slug === type)
  slidersFiltered = slidersFiltered[0]?.slides?.data

  let slides = []
  if (slidersFiltered && slidersFiltered.length) {
    slides = [...slidersFiltered]
    slides.shift()
    slides.push(slidersFiltered[0])
  }

  /* stylelint-disable */
  const SlideHeading = styled(Heading)`
    transition: 0.15s ease-out;
    @supports (-webkit-text-stroke: 1px #000) {
      -webkit-text-stroke: 2px transparent;
    }
    &:hover {
      @supports (-webkit-text-stroke: 1px #000) {
        color: transparent;
        -webkit-text-stroke: 2px #fff;
      }
    }
    ${screen.sm_min} {
      font-size: 64px;
    }
  `
  /* stylelint-enable */
  return (
    <React.Fragment>
      {headingContent.children && (
        <S.SlideSection backgroundText id={id}>
          <Heading
            level={3}
            children={headingContent.children}
            subTitle={headingContent.subTitle}
          />
        </S.SlideSection>
      )}

      {slidersFiltered && (
        <S.Slider template={template}>
          {!headingContent.children && (
            <S.SliderArrow
              href="#next"
              onClick={() => document.querySelector('#next').scrollIntoView({ behavior: 'smooth' })}
            >
              <ArrowDown />
            </S.SliderArrow>
          )}
          <S.SliderMain template={template}>
            <Swiper {...paramsMain} getSwiper={updateSwiperMain}>
              {slidersFiltered.map((slide, index) => (
                <S.Slide key={index}>
                  <S.SlideMedia className="slide-inner">
                    {!slide.attributes.video && (
                      <img
                        src={`${process.env.REACT_APP_HOST}${slide.attributes.image.data.attributes.url}`}
                        alt={`${process.env.REACT_APP_HOST}${slide.attributes.image.data.attributes.alt}`}
                      />
                    )}
                    {slide.attributes.video && (
                      <video
                        src={`${process.env.REACT_APP_HOST}${slide.attributes.video.data.attributes.url}`}
                        poster={`${process.env.REACT_APP_HOST}${slide.attributes.image.data.attributes.url}`}
                        loop
                        autoPlay
                        muted
                        playsInline
                      />
                    )}
                  </S.SlideMedia>

                  <S.SlideContent className="slide-content">
                    {slide.attributes.logo.data.attributes.url && (
                      <img
                        src={`${process.env.REACT_APP_HOST}${slide.attributes.logo.data.attributes.url}`}
                        alt={slide.attributes.title}
                      />
                    )}
                    {slide.attributes.title.indexOf('#no') < 0 && (
                      <SlideHeading level="2" realLevel="1" margin="0 0 40px 0">
                        {slide.attributes.title}
                      </SlideHeading>
                    )}
                    <Text dangerouslySetInnerHTML={{ __html: slide.attributes.description }} />
                    <S.SlideActions>
                      {slide.attributes.buttons.data.slice(0, 2).map(
                        (button, index) =>
                          button.attributes.text && (
                            <Button
                              type={index === 0 ? 'outlineLink' : 'link'}
                              to={button.attributes.url}
                              target={button.attributes.url.charAt(0) === '/' ? '_self' : '_blank'}
                              key={index}
                            >
                              {button.attributes.text}
                            </Button>
                          ),
                      )}
                    </S.SlideActions>
                  </S.SlideContent>
                </S.Slide>
              ))}
            </Swiper>
          </S.SliderMain>

          <S.SliderThumb template={template}>
            <Swiper {...paramsThumb} getSwiper={updateSwiperThumb}>
              {slides.map((slide, index) => (
                <S.Slide key={index} onClick={() => goNext()}>
                  <S.SlideMedia className="slide-inner">
                    {!slide.attributes.video.data.attributes.url && (
                      <img
                        src={`${process.env.REACT_APP_HOST}${slide.attributes.image.data.attributes.url}`}
                        alt={`${process.env.REACT_APP_HOST}${slide.attributes.image.data.attributes.alt}`}
                      />
                    )}
                    {slide.attributes.video.data.attributes.url && (
                      <video
                        src={`${process.env.REACT_APP_HOST}${slide.attributes.video.data.attributes.url}`}
                        poster={`${process.env.REACT_APP_HOST}${slide.attributes.image.data.attributes.url}`}
                        loop
                        autoPlay
                        muted
                        playsInline
                      />
                    )}
                  </S.SlideMedia>

                  <S.SlideContent className="slide-content">
                    {slide.attributes.title.indexOf('#no') !== 0 && (
                      <Heading level="2" realLevel="1">
                        {slide.attributes.title}
                      </Heading>
                    )}
                  </S.SlideContent>
                </S.Slide>
              ))}
            </Swiper>
          </S.SliderThumb>
        </S.Slider>
      )}
      <div id="next" />
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  sliders: selectors.selectSliders(state),
})

export default connect(mapStateToProps)(HeroBanner)
