import React, { useState } from 'react'
import * as selectors from 'store/page/selectors'
import { connect } from 'react-redux'

import BannerPopupItem from './item'

const BannerPopup = ({ popUps }) => {
  const [activePopup, setActivePopup] = useState(0)

  function openNextPopup() {
    if (popUps.length > activePopup + 1) {
      setActivePopup(activePopup + 1)
    }
  }

  return (
    popUps.length > 0 && (
      <BannerPopupItem
        item={popUps[activePopup]}
        key={popUps[activePopup].id}
        onClose={() => openNextPopup()}
      />
    )
  )
}

const mapStateToProps = state => ({
  popUps: selectors.selectPopUp(state),
})

export default connect(mapStateToProps)(BannerPopup)
