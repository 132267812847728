import styled, { css } from 'styled-components/macro'
import Heading from 'components/atoms/Heading'
import { screen } from 'config/theme'

export const HeadingWrapper = styled(Heading)`
  margin-bottom: 35px;
`

export const ModalContent = styled.div`
  max-width: 720px;
  width: 100%;
  margin-left: auto;
  height: 100vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
  transform: translateX(100%);
  transition: all 0.5s ease;
  color: #000;
  background-color: #fff;

  ${screen.sm} {
    max-width: 100%;
  }
`

export const ModalOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  opacity: 0;
  transition: all 0.5s ease;
`

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  pointer-events: none;

  ${({ isActive }) =>
    isActive &&
    css`
      pointer-events: initial;

      ${ModalOverlay} {
        opacity: 1;
      }

      ${ModalContent} {
        transform: none;
      }
    `}
`

export const ModalInfo = styled.div`
  padding: 110px 140px 80px 74px;
  width: 100%;

  ${screen.sm} {
    padding: 110px 30px 80px;
  }
`

export const ModalFooter = styled.div`
  border-top: 1px solid #414141;
  padding-top: 40px;

  > p {
    margin-bottom: 30px;
    max-width: 420px;
  }
`

export const Close = styled.div`
  position: absolute;
  top: 30px;
  left: 22px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;

  &:before,
  &:after {
    content: ' ';
    height: 2px;
    width: 48%;
    background: #000;
    position: absolute;
    top: 50%;
    left: 50%;
    transition: inherit;
  }

  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  :hover {
    border-color: #fa2a47;

    &:before,
    &:after {
      background: #fa2a47;
    }
  }
`

export const Text = styled.div`
  margin-bottom: 40px;
`

export const Column = styled.div`
  display: inline-block;
  width: 50%;
  margin-bottom: 30px;
`

export const ColumnTitle = styled.div`
  color: #a2a2a2;
  margin-bottom: 10px;
`

export const ColumnValue = styled.div`
  font-size: 20px;
  font-weight: 700;
`

export const ClubsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 250px;
  margin-bottom: 60px;

  img {
    filter: invert(1);
    margin-top: 24px;
  }
`
