import React from 'react'
import DefaultTemplate from 'components/templates/Default'
import Section from 'components/atoms/Section'
import Heading from 'components/atoms/Heading'
import Questions from 'components/molecules/Questions'
import TabsContent from 'components/molecules/TabsContent'
import { connect } from 'react-redux'
import * as selectors from 'store/page/selectors'

const Faq = ({ faqs, page, popUps }) => {
  let filteredFaqs = faqs.filter(({ faq_sections: { data } }) => data.length > 0)
  let groupedFaqs = []

  const groupBy = obj => {
    return obj.reduce(
      (result, item) => ({
        ...result,
        [item.faq_sections.data[0]?.attributes?.title]: [
          ...(result[item.faq_sections.data[0]?.attributes?.title] || []),
          item,
        ],
      }),
      {},
    )
  }

  Object.entries(groupBy(filteredFaqs)).map((item, index) => {
    groupedFaqs.push({})
    groupedFaqs[index].title = item[0]
    groupedFaqs[index].content = <Questions key={index + item[0]} template="page" list={item[1]} />
  })

  return (
    <DefaultTemplate slug="faq">
      <Section fullWidth="middle">
        <Heading
          level={2}
          subTitle={page.data.attributes.overhead}
          children={page.data.attributes.name}
          subTitleColor="#888"
        />
        <TabsContent content={groupedFaqs} template="faq" />
      </Section>
    </DefaultTemplate>
  )
}

const mapStateToProps = state => ({
  popUps: selectors.selectPopUp(state),
  page: selectors.selectContent(state),
  faqs: selectors.selectFaqs(state),
})

export default connect(mapStateToProps)(Faq)
