import React from 'react'
import * as S from './styles'
import Modal from 'react-modal'
import { getCookies, setCookies } from '../../../utils/cookies'
import Button from '../../atoms/Button'

const BannerPopupItem = ({ item, onClose }) => {
  const [modalIsOpen, setIsOpen] = React.useState(!Boolean(getCookies('PopupBanner' + item.id)))

  function afterOpenModal() {
    if (item.display_frequency) {
      setCookies('PopupBanner' + item.id, true, { expires: item.display_frequency / 24 })
    } else {
      setCookies('PopupBanner' + item.id, true)
    }
  }

  function closeModal(item) {
    onClose()
    setIsOpen(false)
  }

  return (
    <Modal
      className={'cmp__modal'}
      overlayClassName={'cmp__modal-overlay'}
      ariaHideApp={false}
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      // contentLabel="Example Modal"
    >
      <S.Close onClick={() => closeModal(item)} />
      <S.BannerPopup>
        <S.BannerImage>
          <a
            target={`${
              item.link_url.indexOf('://') > 0 || item.link_url.indexOf('//') === 0 ? '_blank' : ''
            }`}
            href={item.link_url}
            title={item.link_text}
          >
            <picture>
              <source
                media="(orientation: landscape)"
                srcSet={`${process.env.REACT_APP_HOST}${item.image.data.attributes.url}`}
              />
              <img
                src={`${process.env.REACT_APP_HOST}${item.mobi_image.data.attributes.url}`}
                alt="kometa"
              />
            </picture>
          </a>
        </S.BannerImage>
        {item.buttons.data.length > 0 && (
          <S.BannerButton>
            {item.buttons.data.map(button => (
              <Button
                type={`${
                  button.attributes.url.indexOf('://') > 0 ||
                  button.attributes.url.indexOf('//') === 0
                    ? 'outlineLink'
                    : 'outline'
                }`}
                to={button.attributes.url}
                key={button.attributes.url}
                title={button.attributes.text}
                target={`${
                  button.attributes.url.indexOf('://') > 0 ||
                  button.attributes.url.indexOf('//') === 0
                    ? '_blank'
                    : ''
                }`}
              >
                {button.attributes.text}
              </Button>
            ))}
          </S.BannerButton>
        )}
      </S.BannerPopup>
    </Modal>
  )
}

export default BannerPopupItem
