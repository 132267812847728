import React, { useState } from 'react'
import * as S from './styles'
import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'
import ScrollLock, { TouchScrollable } from 'react-scrolllock'
import { getOptimizeImage } from 'utils/helpers'
import LogoSpace from 'components/atoms/Space'

const sliderParams = {
  navigation: {
    nextEl: '.arrow-next',
    prevEl: '.arrow-prev',
  },
  preloadImages: false,
  lazy: true,
  shouldSwiperUpdate: true,
  breakpoints: {
    768: {
      slidesPerView: 2,
      spaceBetween: 0,
    },
    320: {
      slidesPerView: 1,
      spaceBetween: 0,
    },
  },
  speed: 600,
  loadPrevNext: true,
  renderNextButton: () => <S.Arrow className="arrow-next" />,
  renderPrevButton: () => <S.Arrow className="arrow-prev" />,
}

const Popup = ({ item, setOpenItem }) => {
  const [closePopup, setClosePopup] = useState(false)
  const [startAnimation, setStartAnimation] = useState(false)

  setTimeout(() => setStartAnimation(true), 1000)

  const delayCloseItem = () => {
    setOpenItem(null)
  }

  const closeItem = () => {
    setClosePopup(true)
    setTimeout(() => delayCloseItem(), 600)
  }

  return (
    <S.Popup className="-is-active--true" isClose={closePopup}>
      <ScrollLock />
      <TouchScrollable>
        <S.OverflowPopup>
          <S.SectionPopup>
            <S.Content>
              <S.ContentInner>
                <S.Logo>
                  <LogoSpace name={item.attributes.name} active={true} />
                </S.Logo>
                <S.Title dangerouslySetInnerHTML={{ __html: item.attributes.title }} />
                <S.Description dangerouslySetInnerHTML={{ __html: item.attributes.description }} />
              </S.ContentInner>
              <S.Slider>
                <Swiper {...sliderParams}>
                  {item.attributes.uploaded_files.data.slice(3).map((item, index) => (
                    <S.Slide key={index} isOpen={startAnimation}>
                      <img
                        src={getOptimizeImage(item.attributes.url, { width: '600', height: '400' })}
                        alt={item.attributes.alt}
                      />
                    </S.Slide>
                  ))}
                </Swiper>
              </S.Slider>
            </S.Content>
          </S.SectionPopup>
        </S.OverflowPopup>
      </TouchScrollable>
      <S.Close onClick={() => closeItem()} />
      <S.BackgroundContent
        background={getOptimizeImage(item.attributes.uploaded_files.data[2].attributes.url, {
          width: '900',
          height: '505',
        })}
        isVisible={true}
      />
    </S.Popup>
  )
}

export default Popup
