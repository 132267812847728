import styled, { css } from 'styled-components/macro'
import LocationIcon from 'assets/svg/location.svg'
import Button from 'components/atoms/Button'
import Section from 'components/atoms/Section'
import { screen } from 'config/theme'

export const SectionWrapper = styled(Section)`
  overflow: initial;

  ${screen.lg_min} {
    padding-bottom: 100px;
  }

  ${screen.md_max} {
    padding-bottom: 70px;
  }
  ${screen.xs} {
    @media (max-width: 1080px)
  }
`

export const Block = styled.div`
  ${screen.md_min} {
    padding-top: 70px;
    padding-bottom: 20px;
  }

  ${screen.md} {
    padding-top: 50px;
    padding-bottom: 40px;
  }
`

export const Title = styled.div`
  font-weight: 700;

  ${screen.xs_min} {
    font-size: 28px;
    margin-bottom: 10px;
  }

  ${screen.xs} {
    font-size: 24px;
    margin-bottom: 18px;
  }
`

export const SubTitle = styled.div`
  font-size: 16px;
  color: #888;
  max-width: 724px;
  margin-bottom: 50px;

  ${screen.xs} {
    font-size: 14px;
  }
`

export const Line = styled.div`
  ${screen.lg_min} {
    display: flex;
    margin: 0 -38px;
  }

  ${screen.sm_max} {
    margin: 0;
  }
`

export const ItemWrapper = styled.div`
  ${screen.lg_min} {
    padding: 0 38px;
    flex-grow: 1;
    width: 0;
  }

  ${screen.lg} {
    width: 100%;
    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${screen.xs} {
    margin-bottom: 90px;
  }

  ${screen.sm_max} {
    padding: 0;
  }
`

export const Item = styled.div`
  position: relative;
  z-index: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${screen.xs_min} {
    padding: 40px 60px;
    /* stylelint-disable */
    box-shadow: 0px 80px 90px -40px rgba(255, 255, 255, 0.2),
      0px 40px 30px -35px rgba(255, 255, 255, 0.2);
    /* stylelint-enable */
  }

  ${screen.xs} {
    padding: 0 22px;
  }
`

export const Background = styled.div`
  ${screen.xs_min} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  ${screen.xs} {
    position: relative;
    margin: 0 -52px -90px;
    z-index: -1;
  }

  &:before {
    content: ' ';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.9;

    ${screen.xs_min} {
      background: linear-gradient(195deg, rgba(0, 0, 0, 0.35) 14%, #000 85%);
    }

    ${screen.xs} {
      background: linear-gradient(360deg, #000 0%, rgba(0, 0, 0, 0) 78%);
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const Logo = styled.div`
  display: flex;

  ${screen.xs_min} {
    margin-bottom: 16px;
  }

  ${screen.xs} {
    margin-bottom: 0;

    ${/* sc-selector */ ItemWrapper}:only-child & {
    }
  }

  img {
    max-width: 100%;
    height: 70px;
  }
`

export const Text = styled.div`
  margin-bottom: auto;
  position: relative;

  ${screen.xs} {
    font-size: 14px;
  }

  &:before {
    content: url(${LocationIcon});
    position: absolute;
    top: 0;
    left: -22px;
  }
`

export const ButtonWrapper = styled(Button)`
  vertical-align: middle;

  ${({ type }) =>
    type === 'border' &&
    css`
      margin-top: 20px;
    `}

  ${({ type }) =>
    type === 'route' &&
    css`
      font-size: 16px;
      padding: 0 24px;
      margin-top: 30px;
      margin-right: 30px;
      min-height: 40px;
      min-width: initial;
      @media (max-width: 374px) {
        margin-right: 0;
        padding: 0 12px;
      }
    `}
`
