import styled, { css } from 'styled-components/macro'
import { screen } from 'config/theme'
import Section from '../../atoms/Section'

export const SectionWrapper = styled(Section)`
  padding-top: 0;
`

export const Social = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: left;

  ${screen.sm} {
    max-width: 300px;
    margin: auto;
  }

  ${screen.md_min} {
    max-width: 480px;
  }
`

export const SocialItem = styled.div`
  ${screen.md_min} {
    display: flex;
    align-items: center;
  }
`

export const SocialLinkWrap = styled.div`
  display: flex;
  flex-wrap: no-wrap;

  ${screen.sm} {
    margin-top: 10px;
  }

  ${screen.md_min} {
    margin-left: 10px;
  }
`

export const SocialLink = styled.a`
  display: inline-block;
  margin-right: 18px;
  transition: all 0.3s ease;
  opacity: 0.7;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;

  ${screen.sm} {
    margin-right: 8px;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    opacity: 1;
  }
`

export const Wrapper = styled.div`
  a:not(${/* sc-selector */ SocialLink}) {
    border-bottom: 1px solid;
    transition: all 0.3s ease;

    &:hover {
      border-color: transparent;
    }
  }
`

export const Addresses = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 20px;

  ${screen.sm_min} {
    border-bottom: 1px solid #414141;
  }

  ${({ clubPage }) =>
    clubPage &&
    css`
      border-bottom: none !important;
      & > div {
        margin-bottom: 0px;
      }
    `}
`

export const TopLine = styled.div`
  padding: 75px 0;
`

export const Column = styled.div`
  margin-bottom: 65px;
  padding-right: 30px;
  width: calc(100% / 3);

  ${({ cols }) =>
    cols % 2 === 0 &&
    css`
      width: 50%;
    `}

  ${({ clubPage }) =>
    clubPage &&
    css`
      width: calc(100% / 3 * 2);

      ${Info} {
        padding-top: 55px;

        div {
          margin-bottom: 0px !important;
        }
      }
    `}

  ${screen.md} {
    width: 50%;
  }

  ${screen.sm} {
    width: 100%;

    br {
      display: none;
    }
  }
`

export const Title = styled.div`
  height: 34px;
  display: inline-flex;
  align-items: center;
  margin-bottom: 22px;
  max-width: 100%;
  width: 100%;

  a {
    display: inline-block;
    width: 100%;
    border-bottom: 0 !important;
  }

  img {
    max-width: 280px;
  }
`

export const Text = styled.div``

export const Info = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 74px;
  font-size: 20px;

  div {
    &:not(:last-child) {
      margin-bottom: 20px;
      margin-right: 55px;
    }
  }

  ${({ contacts }) =>
    contacts &&
    css`
      flex-direction: column;

      & > div {
        margin-bottom: 0;
        width: 100%;
      }

      b {
        font-size: 40px;
      }
    `};

  span {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
`

export const BottomLine = styled.div`
  display: flex;
  padding: 54px 40px;
  border-top: 1px solid #414141;

  ${screen.md} {
    flex-wrap: wrap;
  }

  ${screen.sm} {
    flex-direction: column-reverse;
    align-items: center;
    padding: 34px;
  }
`

export const BottomLineColumn = styled.div`
  ${screen.sm} {
    text-align: center;
    margin-bottom: 60px;
    width: 100%;
  }

  ${screen.md_sm} {
    max-width: calc(100% / 3);
    flex: 0 0 calc(100% / 3);

    &:nth-child(1) {
      max-width: 60%;
      flex: 0 0 60%;
    }

    &:nth-child(2) {
      max-width: 40%;
      flex: 0 0 40%;
    }

    &:nth-child(3) {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }

  ${screen.md_min} {
    max-width: calc(100% / 3);
    flex: 0 0 calc(100% / 3);

    &:nth-child(1) {
      max-width: 25%;
      flex: 0 0 25%;
    }

    &:nth-child(2) {
      max-width: 50%;
      flex: 0 0 50%;
    }

    &:nth-child(3) {
      max-width: 25%;
      flex: 0 0 25%;
    }
  }

  &.footer-logo {
    margin-bottom: 0;
  }

  &.footer-rules {
    ${screen.md_min} {
      padding-left: 30px;
    }
  }

  &.footer-social {
    text-align: center;

    ${screen.sm} {
      order: 2;
      margin-bottom: 40px;
    }
  }
`

export const Rules = styled.div`
  font-size: 12px;

  ${screen.md_sm} {
    margin-top: 20px;
  }

  p {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    ${screen.md_sm} {
      text-align: center !important;
    }

    ${screen.sm} {
      text-align: center !important;
    }
  }
`

export const FooterLogo = styled.div`
  opacity: 0.3;
  max-width: 185px;
  max-height: 50px;

  img {
    object-fit: contain;
  }

  ${screen.sm_min} {
    margin-right: auto;
  }

  ${screen.sm} {
    margin-bottom: 30px;
  }

  svg {
    fill: #d1d1d1;
  }
`
