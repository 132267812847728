import styled, { css } from 'styled-components/macro'
import { screen } from 'config/theme'
import Masonry from 'react-masonry-css'

export const Container = styled(Masonry)`
  display: flex;
  width: auto;
  margin: 0 -38px;
  padding-top: 70px;

  ${screen.sm} {
    margin: 0;
  }

  ${({ type }) =>
    type === 'black' &&
    css`
      margin: 0 -76px;
      ${screen.lg} {
        margin: 0 -55px;
      }
      ${screen.md} {
        margin: 0 -35px;
      }
      ${screen.xs} {
        margin: 0;
      }
    `}
`

export const ItemShadow = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% - 60px);
  height: calc(100% - 84px);
  transform: translate(-50%, -50%);
  z-index: -2;
  transition: all 0.5s ease;
  /* stylelint-disable */
  box-shadow: 0px 80px 90px rgba(255, 255, 255, 0.2), 0px 40px 30px rgba(255, 255, 255, 0.2);
  /* stylelint-enable */
`

export const Item = styled.div`
  padding: 0 38px 96px;
  display: inline-block;
  width: 100%;
  will-change: transform;

  ${screen.lg} {
    padding: 0 19px 48px;
  }

  ${screen.sm} {
    display: block;
    padding: 0 0 48px;
  }

  ${({ type }) =>
    type === 'black' &&
    css`
      padding: 0 76px 116px;
      ${screen.lg} {
        padding: 0 55px 96px;
      }
      ${screen.md} {
        padding: 0 35px 70px;
      }
      ${screen.xs} {
        padding: 0 0 50px;
      }
    `}
`

export const ItemContent = styled.div`
  position: relative;
  will-change: transform;
  cursor: pointer;

  &:hover {
    ${ItemShadow} {
      opacity: 0;
    }

    button {
      border-color: #fa2a47;

      span {
        color: #fa2a47;
      }
    }
  }
`
